import React from 'react'
import Layout from '../Header/Layout'
import Footer from '../Footer/Footer'
import { Box } from '@mui/material'
import "./PrivacyPolicy.css"

function RetailPrivacyPolicy() {
    return (
        <Layout>
            <Box sx={{ width: "100%", padding: { xs: "20px", md: "20px 80px" } }}>
                <h2>Privacy Policy</h2>
                <div className='privacy-policy-container'>
                    <h3>Introduction</h3>
                    <p>Welcome to the Polarize Network Investment Dashboard. This Privacy Policy describes how Polarize Network Pvt. Ltd. (the "Company", "we", "us", or "our") collects, uses, discloses, and safeguards your information when you use our investment monitoring dashboard (the "Dashboard"). We value the privacy of our users and are committed to protecting your personal data.6</p>
                    <h3>Information Collection</h3>
                    <p>When you use our Dashboard, we may collect the following types of information:</p>
                    <ol className="list-style">
                        <li>Personal Identification Information: Name, email address, phone number, and any other contact details you provide.</li>
                        <li>Financial Information: Details about your investments, transaction history, account balances, and other related financial data.</li>
                        <li>Usage Data: Information about how you use the Dashboard, including your interaction with various features and preferences.</li>
                        <li>Technical Data: IP address, login data, browser type and version, time zone setting and location, browser plug-in types and versions, operating system and platform, and other technology on the devices you use to access the Dashboard.</li>
                    </ol>
                    <h3>Use of Information</h3>
                    <p>The information we collect from you may be used in one of the following ways:</p>
                    <ul>
                        <li>To personalize your experience and to allow us to deliver the type of content and product offerings in which you are most interested.</li>
                        <li>To improve our Dashboard in order to better serve you.</li>
                        <li>To manage your account and provide you with customer support.</li>
                        <li>To perform and analyze our marketing efforts.</li>
                        <li>To comply with applicable laws, regulations, and legal requests from authorities.</li>
                    </ul>
                    <h3>Information Sharing and Disclosure</h3>
                    <p>We do not sell, trade, or otherwise transfer to outside parties your personally identifiable information unless we provide users with advance notice. This does not include website hosting partners and other parties who assist us in operating our website, conducting our business, or serving our users, so long as those parties agree to keep this information confidential. We may also release information when it's release is appropriate to comply with the law, enforce our site policies, or protect ours or others' rights, property or safety.</p>
                    <h3>Data Security</h3>
                    <p>We implement a variety of security measures to maintain the safety of your personal information when you enter, submit, or access your personal information. We use encryption and secure servers to ensure that your data is protected against unauthorized access, alteration, disclosure, or destruction.</p>
                    <h3>Your Rights</h3>
                    <p>You have certain rights concerning your personal information, including the right to access, correct, or delete your personal information held by us. You can exercise these rights by contacting us at the contact information below.</p>
                    <h3>Changes to This Privacy Policy</h3>
                    <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes.</p>
                    <h3>Contact Us</h3>
                    <p>If you have any questions about this Privacy Policy, please contact us:</p>
                    <ul>
                        <li>Email: support@polarizenetwork.com </li>
                        <li>Whatsapp: +917020015025</li>
                        <li>Address: 304, 3rd Floor, Biz Square, Near Kamalnayan Bajaj Hospital, Beed Bypass Road, Chh. Sambhaji Nagar (Aurangabad), Maharashtra- 431005</li>
                    </ul>
                </div>
            </Box>
            <Footer />
        </Layout>
    )
}

export default RetailPrivacyPolicy