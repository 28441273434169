import React from 'react'
import DashboardNavBar from './Navbar'
import { Box } from '@mui/material'

function Layout({ children, userData }) {
    return (
        <>
            <DashboardNavBar userData={userData} />
            <Box sx={{ marginTop: "109px" }}>
                {children}
            </Box>
        </>
    )
}

export default Layout