import React, { useState } from 'react'
import { Button } from '@mui/material'
import Styles from './PreSaleComingSoon.module.scss'
import { NavLink } from 'react-router-dom'

//IMPORT IMAGES
import ComingSoonImg from '../../../resources/images/presale/coming_soon.svg'
import HalfCircle from '../../../resources/images/halfCircle.png'
import { HiOutlineLockClosed } from 'react-icons/hi'
import { BusdSvg, ThreeDotsSvg, UsdtSvg } from '../../Svgs/Svgs'

const PreSaleComingSoon = () => {
  const [selectedOption, setSelectedOption] = useState('usdt')

  console.log('selectedOption', selectedOption)

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value)
  }

  return (
    <>
      <section className={Styles.ps_cs}>
        <div className={Styles.ps_cs_wrap}>
          <div className={Styles.ps_cs_wrap_img}>
            <div className="hidden">
              <div data-aos="fade-up">
                {' '}
                <img src={ComingSoonImg} alt="coming_soon" />
              </div>
            </div>
          </div>

          <div className={Styles.ps_cs_wrap_box}>
            <div className={Styles.ps_cs_wrap_box_left}>
              <div className="hidden">
                <div data-aos="fade-up" className="df_g24_col">
                  <div className={Styles.ps_cs_wrap_box_left_title}>
                    Presale Stage 1
                  </div>
                  <div className={Styles.ps_cs_wrap_box_left_counter}>
                    <div className={Styles.ps_cs_wrap_box_left_counter_timer}>
                      <ul>
                        <li className={Styles.number}>00</li>
                        <li className={Styles.text}>Day</li>
                      </ul>
                      <ul>
                        <li className={Styles.number}>00</li>
                        <li className={Styles.text}>Hrs</li>
                      </ul>
                      <ul>
                        <li className={Styles.number}>00</li>
                        <li className={Styles.text}>Min</li>
                      </ul>
                      <ul>
                        <li className={Styles.number}>00</li>
                        <li className={Styles.text}>Sec</li>
                      </ul>
                    </div>
                    <div
                      style={{ '--percentage': '2%' }}
                      className={Styles.ps_cs_wrap_box_left_counter_progress}
                    ></div>
                  </div>
                  <div className={Styles.ps_cs_wrap_box_left_data}>
                    <div className={Styles.ps_cs_wrap_box_left_data_box}>
                      <div className={Styles.name}>USDT RAISED</div>
                      <div className={Styles.value}>$ 0 / $0</div>
                    </div>
                    <div className={Styles.ps_cs_wrap_box_left_data_box}>
                      <div className={Styles.name}>
                        YOUR PURCHASED PLR Token
                      </div>
                      <div className={Styles.value}>$ 0 / $0</div>
                    </div>
                    <div className={Styles.ps_cs_wrap_box_left_data_box}>
                      <div className={Styles.name}>
                        YOUR STAKEABLE PLR Token
                      </div>
                      <div className={Styles.value}>$ 0 / $0</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={Styles.ps_cs_wrap_box_right}>
              <div className="hidden">
                <div data-aos="fade-up" className="df_g24_col">
                  <div className={Styles.ps_cs_wrap_box_right_buttons}>
                    <div>
                      <input
                        type="radio"
                        value="usdt"
                        name="presale"
                        id="usdt"
                        checked={selectedOption === 'usdt'}
                        onChange={handleOptionChange}
                      />
                      <label htmlFor="usdt">
                        <UsdtSvg />
                        USDT
                      </label>
                    </div>

                    <div>
                      {' '}
                      <input
                        type="radio"
                        value="busd"
                        name="presale"
                        id="busd"
                        checked={selectedOption === 'busd'}
                        onChange={handleOptionChange}
                      />
                      <label htmlFor="busd">
                        <BusdSvg />
                        BUSD
                      </label>
                    </div>

                    <div>
                      <input
                        type="radio"
                        value="usdc"
                        name="presale"
                        id="usdc"
                        checked={selectedOption === 'usdc'}
                        onChange={handleOptionChange}
                      />

                      <label htmlFor="usdc">
                        <BusdSvg />
                        USDC
                      </label>
                    </div>
                    <div>
                      <input
                        type="radio"
                        value="more"
                        name="presale"
                        id="more"
                        checked={selectedOption === 'more'}
                        onChange={handleOptionChange}
                      />
                      <label htmlFor="more">
                        More
                        <ThreeDotsSvg />
                      </label>
                    </div>
                  </div>
                  <div className={Styles.ps_cs_wrap_box_right_inputs}>
                    <div
                      className={Styles.ps_cs_wrap_box_right_inputs_inputBox}
                    >
                      <div className={Styles.label}>
                        Amount in USDT You Pay
                      </div>
                      <input type="text" placeholder="0" />
                    </div>
                    <div
                      className={Styles.ps_cs_wrap_box_right_inputs_inputBox}
                    >
                      <div className={Styles.label}>
                        Amount in PLR Token You Receive
                      </div>
                      <input type="text" placeholder="0" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={Styles.ps_cs_wrap_button}>
            <div style={{ width: '100%' }} className="hidden ">
              <div style={{ width: '100%' }} data-aos="fade-up">
                <Button
                  variant="contained"
                  sx={{
                    borderRadius: '33px',
                    color: 'black',
                    py: '10px',
                    width: '100%',
                    maxWidth: '292px',
                    bgcolor: '#FFE500',
                    fontSize: 12,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '6px',
                    margin: '0 auto',
                  }}
                  LinkComponent={NavLink}
                  //   to={item.link}
                >
                  Contact Support
                  {/* <img src={UsdtImg} alt="Lock-Icon" /> */}
                  <HiOutlineLockClosed
                    className={Styles.ps_cs_wrap_button_icon}
                  />
                </Button>{' '}
              </div>
            </div>
          </div>

          <div
            className="df"
            style={{
              position: 'absolute',
              bottom: '-100px',
              right: 0,
              left: '50%',
              transform: 'translateX(-50%)',
              height: '100%',
              width: '100%',
              zIndex: 0,
            }}
          >
            <img
              data-aos="fade-up"
              className="mob_hide"
              src={HalfCircle}
              alt="halfCircle"
            />
          </div>
        </div>
      </section>
    </>
  )
}

export default PreSaleComingSoon
