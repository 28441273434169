import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { format, addMonths, parseISO, differenceInMonths, isBefore, isEqual } from 'date-fns';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const InvestmentChart = ({ userData }) => {
  const [chartData, setChartData] = useState();

  const generateData = (userData) => {
    const startDate = userData?.time_created ? parseISO(userData.time_created) : new Date();
    // const startDate = userData?.time_created ? parseISO("2021-07-22") : new Date();
    const today = new Date();
    const totalMonths = differenceInMonths(today, startDate) || 1;

    const months = [];
    const values = [];
    const capitalIncrement = userData?.capital_invested / 36; // Monthly increment based on portfolio_value and months between start and today

    let currentValue = userData?.capital_invested;
    let currentMonth = startDate;

    for (let i = 0; i < 36; i++) {
      months.push(format(addMonths(startDate, i), 'MMM yyyy'));

      if (i < totalMonths) {
        values.push(currentValue);
        currentValue += capitalIncrement;
      } else {
        values.push(null); // No value for future months
      }

      currentMonth = addMonths(currentMonth, 1);
    }

    return {
      labels: months,
      datasets: [
        {
          label: 'Investment',
          data: values,
          backgroundColor: 'rgba(255, 235, 59, 0.5)',
          borderColor: '#ffeb3b',
          borderWidth: 1,
        },
      ],
    };
  };

  useEffect(() => {
    if (userData?.capital_invested) {
      const data = generateData(userData);
      setChartData(data);
    }
  }, [userData]);

  const data = {
    labels: ['Jan', 'Feb', 'March', 'April', 'May', 'June', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    datasets: [
      {
        label: 'Investment',
        data: [0, 50000, 100000, 75000, 150000, 50000, 75000, 100000, 125000, 100000, 150000, 300000],
        backgroundColor: 'rgba(255, 235, 59, 0.5)',
        borderColor: '#ffeb3b',
        borderWidth: 1,
      },
    ],
  };

  const capitalInvested = userData?.capital_invested || 0;
  const maxValue = capitalInvested * 2;
  const stepSize = maxValue / 10;

  // const yAxisValues = Array.from({ length: 6 }, (_, i) => capitalInvested + stepSize * i);
  const yAxisValues = Array.from({ length: Math.ceil(maxValue / stepSize) + 1 }, (_, i) => i * stepSize);
  console.log("yyyyyy", yAxisValues, stepSize, maxValue)

  const allYAxisValues = [0, ...yAxisValues];

  const options = {
    scales: {
      y: {
        beginAtZero: true,
        min: allYAxisValues[0],
        max: allYAxisValues[allYAxisValues.length - 1],
        ticks: {
          color: '#fff',
          stepSize: 1,
          callback: function (value) {
            if (allYAxisValues.includes(value)) {
              return value.toLocaleString('en-US', { style: 'currency', currency: 'INR' });
            }
            return null;
          },
        },
        grid: {
          color: '#aaa', // Color of grid lines
          lineWidth: 1, // Line width of grid lines
          drawBorder: false, // Optionally hide the border line
          drawOnChartArea: true, // Ensure grid lines are drawn on the chart area
          drawTicks: true, // Draw ticks on the x-axis
          borderDash: [2, 2], // Dash style for the grid lines
          tickLength: 10, // Length
        },
      },
      x: {
        ticks: {
          color: '#fff',
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            return tooltipItem.raw.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
          },
        },
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    hover: {
      mode: 'nearest',
      intersect: true,
    },
  };

  return (
    <div className="chart-container">
      <h2>Chart of Investment</h2>
      <Bar data={chartData || data} options={options} style={{ width: '100%' }} />
    </div>
  );
};

export default InvestmentChart;
