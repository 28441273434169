import { TextField } from '@mui/material'
import { useState } from 'react'
import useRegisterUserStore from '../../../../store/registerUser'

const Cash = () => {
  const { recieverName, addRecieverName } = useRegisterUserStore()
  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '10px',
        }}
      >
        <div>Receivers Name</div>
        <TextField
          type="text"
          placeholder="Enter your Name"
          fullWidth
          variant="outlined"
          value={recieverName}
          onChange={(e) => addRecieverName(e.target.value)}
          className="text-field"
        />
      </div>
    </>
  )
}

export default Cash
