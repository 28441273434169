import {
  Box,
  Button,
  Container,
  Grid,
  Typography,
  useTheme,
} from '@mui/material'
import React from 'react'
import { Helmet } from 'react-helmet'
import NavBar from '../components/Navbar'
import Bg from '../resources/images/Bg.png'
import Image from '../resources/images/PLR_Mart.png'
import Ellipse from '../resources/images/Ellipse15.png'
import { Link } from 'react-router-dom'
import { ReactComponent as ArrowIcon } from '../resources/images/Arrow_Icon_Black.svg'
import ComingSoonImage from '../resources/images/Coming_Soon_Image.svg'

const PLRMart = () => {
  const theme = useTheme()
  return (
    <>
      <Helmet>
        <title>PLR Mart | {process.env.REACT_APP_APP_NAME}</title>
      </Helmet>
      <NavBar />

      <Container maxWidth="xl">
        <Box
          sx={{
            // marginTop: { xs: '25px', md: '30px' },
            minHeight: '516px',
            '::after': {
              content: "' '",
              backgroundImage: `url(${Bg})`,
              backgroundPosition: 'top center',
              backgroundRepeat: 'no-repeat',
              position: 'absolute',
              top: 0,
              left: 0,
              height: '100%',
              width: '100%',
              objectFit: 'cover',
              zIndex: -1,
            },
          }}
          position="relative"
        >
          <Box position="absolute" right={0} top={300}>
            <img src={Ellipse} alt="" />
          </Box>
          <Typography
            variant="xss"
            letterSpacing="0.7px"
            textAlign="center"
            display="block"
            maxWidth="1307px"
            margin="auto"
            mt="5px"
          >
            PLR Mart
          </Typography>
          <Typography
            lineHeight={1.36}
            variant="h4"
            mt="10px"
            letterSpacing="0.44px"
            fontWeight={400}
            textAlign="center"
            display="block"
            maxWidth="1142px"
            mx="auto"
            sx={{ opacity: 0.8 }}
            px="20px"
          >
            Explore a futuristic shopping experience at PLR Mart, Polarize
            Network's e-commerce platform.
          </Typography>
          <Grid
            container
            pl={{ xs: '30px', sm: '87px', md: '203px' }}
            pr={{ xs: '30px', sm: '87px' }}
            mt="50px"
            pb="100px"
            columnSpacing="73px"
          >
            <Grid item xs={12} md={6}>
              <Typography
                variant="h7"
                fontWeight={400}
                lineHeight={1.95}
                letterSpacing="0.4px"
                sx={{ opacity: 0.6 }}
                display="block"
                maxWidth="527px"
              >
                Step into the future of e-commerce with PLR Mart, the
                cutting-edge platform within the Polarize Network ecosystem.
                Designed to enhance your shopping experience, PLR Mart
                seamlessly integrates blockchain technology, providing a secure
                and transparent environment for transactions. Utilize PLR Tokens
                to unlock exclusive deals on a diverse array of products, from
                fashion to electronics, and enjoy a shopping adventure where
                cryptocurrency sophistication meets convenience.
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} position="relative" top={-30}>
              <img alt="" src={Image} />
              {/* <Button
              variant="contained"
              sx={{
                padding: '20px 22px',
                borderRadius: '34px',
                color: theme.palette.common.black,
                fontFamily: 'Krona One',
                fontSize: 12,
                fontWeight: 400,
                letterSpacing: '0.56px',
                maxWidth: '294px',
                width: '100%',
                lineHeight: 1,
                maxHeight: '57px',
                mx: 'auto',
                display: 'flex',
                mt: '10px',
              }}
              LinkComponent={Link}
              // to="/tokenomics"
            >
              Coming Soon <ArrowIcon height="32px" width="32px" />
            </Button> */}

              <Box>
                <img src={ComingSoonImage} alt="" style={{ margin: 'auto' }} />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  )
}

export default PLRMart
