import { Button, TextField } from '@mui/material'
import { useState } from 'react'
import { Link } from 'react-router-dom'

const VerifyOTP = ({ handleSubmit }) => {
  const [otp, setOtp] = useState(new Array(4).fill(''))

  const handleChange = (element, index) => {
    const value = element.value
    if (/[^0-9]/.test(value)) return

    setOtp([...otp.map((d, idx) => (idx === index ? value : d))])

    if (element.nextSibling) {
      element.nextSibling.focus()
    }
  }

  const OTPHandler = (e) => {
    e.preventDefault()
    handleSubmit()
    console.log('Entered OTP:', otp.join(''))
  }
  return (
    <>
      <div className="forgot-sub-container">
        <div style={{ fontSize: '24px', fontWeight: 'bold' }}>
          Verification Code
        </div>
        <div>Enter the verification code your recieved</div>
        <div className="otp-container">
          {otp.map((data, index) => (
            <TextField
              key={index}
              variant="outlined"
              value={data}
              onChange={(e) => handleChange(e.target, index)}
              inputProps={{ maxLength: 1 }}
              className="code-text-field"
            />
          ))}
        </div>
      </div>
      <div style={{ fontSize: '15px' }}>
        Didn't receive the OTP?{' '}
        <Link href="#" style={{ color: '#FFE500' }}>
          Resend OTP
        </Link>
      </div>
      <Button
        onClick={OTPHandler}
        variant="contained"
        fullWidth
        style={{ borderRadius: 50, minHeight: '48px', color: '#000' }}
      >
        Recieve OTP
      </Button>
    </>
  )
}

export default VerifyOTP
