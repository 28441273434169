import React from 'react'
import SuccessIcon from '../../Svgs/Success'

const SuccessDialog = ({ error, message }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        textAlign: 'center',
        alignItems: 'center',
        gap: '20px',
      }}
    >
      {error ? (
        <div
          style={{
            minWidth: '500px',
            minHeight: '100px',
            color: 'red',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {message}
        </div>
      ) : (
        <div>
          <SuccessIcon />
          <div style={{ fontSize: '24px' }}>Thank You! </div>
          <div style={{ fontSize: '18px' }}>
            {`We’ve received your response. Our team will contact you within the next
    24 working hours.`}
          </div>
        </div>
      )}
    </div>
  )
}

export default SuccessDialog
