import { Box, Grid, Tab, TextField } from '@mui/material'
import './retail.css'
import Money from '../../components/Svgs/Money'
import LogoFinal from '../../resources/images/Polarize_Logo_Final.svg'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { useState } from 'react'
import Login from '../../components/Retail/Login'
import Signup from '../../components/Retail/SignUp'
import { useNavigate, useParams } from 'react-router-dom'
import ForgetPassword from '../../components/Retail/ForgetPassword'

const Retail = ({ forgotPassword }) => {
  const path = useParams()
  const navigate = useNavigate()
  const [value, setValue] = useState(path?.params === 'signup' ? '2' : '1')

  const handleChange = (event, newValue) => {
    setValue(newValue)
    navigate(`/retail/${newValue === '1' ? 'login' : 'signup'}`)
  }
  return (
    <div className="retail-container">
      <div className="image-container">
        <Money />
        <div className="image-items">
          <div style={{ fontSize: '20px' }}>Polarize Network</div>
          <div style={{ fontSize: '16px', color: '#D9D9D9' }}>
            Success in investment is about consistency and long-term vision, not
            quick gains.
          </div>
        </div>
      </div>
      <div className="form-container">
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <img
            src={LogoFinal}
            style={{
              maxHeight: 'inherit',
              maxWidth: 'inherit',
              height: '62px',
              width: '180px',
            }}
          />
        </div>
        {forgotPassword ? (
          <ForgetPassword />
        ) : (
          <div className="tab-container">
            <TabContext value={value}>
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
              >
                <Tab label="Login" value="1" style={{ width: '50%' }} />
                <Tab label="Register" value="2" style={{ width: '50%' }} />
              </TabList>
              <TabPanel value="1" style={{ marginTop: '40px' }}>
                <Login />
              </TabPanel>
              <TabPanel value="2" style={{ marginTop: '40px' }}>
                <Signup />
              </TabPanel>
            </TabContext>
          </div>
        )}
      </div>
    </div>
  )
}

export default Retail
