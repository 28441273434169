import axios from "axios";
import { refreshToken } from "../Services/User";

export const API_BASE_URL = "https://staking.polarizenetwork.com"

const axiosInstance = axios.create({
    baseURL: API_BASE_URL
});

axiosInstance.interceptors.request.use(
    (config) => {
        // Get the token from your authentication source
        const token = localStorage.getItem('accessToken'); // Example: storing the token in localStorage
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// Response interceptor to handle token expiration
axiosInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error) => {
        console.log("eoooe", error)
        const originalRequest = error.config;
        if (error.response.status === 403 && !originalRequest._retry) {
            originalRequest._retry = true;
            const newAccessToken = await refreshToken();
            if (newAccessToken) {
                axios.defaults.headers.common['Authorization'] = `Bearer ${newAccessToken}`;
                originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
                localStorage.setItem('accessToken', newAccessToken?.idToken)
                localStorage.setItem('refreshToken', newAccessToken?.refreshToken)
                return axiosInstance(originalRequest);
            } else {
                // Handle refresh token failure (e.g., redirect to login page)
                window.location.href = '/retail/login';
            }
        }
        return Promise.reject(error);
    }
);

const axiosFileInstance = axios.create({
    baseURL: API_BASE_URL,
    headers: {
        'Content-Type': 'multipart/form-data',
    },
});

axiosFileInstance.interceptors.request.use(
    (config) => {
        // Get the token from your authentication source
        const token = localStorage.getItem('accessToken'); // Example: storing the token in localStorage
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export { axiosFileInstance };

export default axiosInstance;