import { Button, Dialog, DialogContent, IconButton, TextField } from '@mui/material'
import { useState } from 'react'
import { resetPassword } from '../../../../Services/User';
import CloseIcon from '@mui/icons-material/Close'
import { useNavigate } from 'react-router-dom';
import SuccessDialog from './SuccessDialog';

const VerifyEmail = () => {
  const [email, setEmail] = useState('');
  const [successDialog, setSuccessDialog] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async () => {

    try {
      const data = await resetPassword(email);
      if (data?.kind === "identitytoolkit#GetOobConfirmationCodeResponse") {
        setSuccessDialog(true)
      } else if (data?.error?.message === "INVALID_EMAIL") {
        setError("Please enter valid email address.");
        setSuccessDialog(true)
      }
    } catch (error) {
      console.log('error: ', error);
    }
  }

  const handleClose = () => {
    setSuccessDialog(false);
    if (error) {
      setError("");
      return;
    }
    navigate("/retail/login");
  }

  return (
    <>
      <div className="forgot-sub-container">
        <div style={{ fontSize: '24px', fontWeight: 'bold' }}>
          Forgot Password
        </div>
        <div>
          Enter your email address associated with your account and we’ll send
          you a OTP to reset your password
        </div>
        <div className="sub-container">
          <div>Email ID</div>
          <TextField
            type="email"
            placeholder="Enter your email address"
            fullWidth
            variant="outlined"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
      </div>
      <Button
        onClick={handleSubmit}
        variant="contained"
        fullWidth
        style={{ borderRadius: 50, minHeight: '48px', color: '#000' }}
        className="submit-button"
      >
        Submit
      </Button>
      <Dialog
        open={successDialog}
        maxWidth={'md'}
        style={{ fontFamily: 'Krona One' }}
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <SuccessDialog error={error} message={error} />
        </DialogContent>
      </Dialog>
    </>
  )
}

export default VerifyEmail
