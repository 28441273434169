import React from 'react'
import DropZone from './DropZone';
import UploadIcon from '../resources/images/upload-01.png'
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close'

function FileUpliadBox({ uploadedFile, onDropHandler, clearFile, disabled }) {
    return (
        <>
            <DropZone onDropHandler={onDropHandler} disabled={disabled}>
                <div
                    style={{
                        padding: '10px',
                        background: '#000000',
                        borderRadius: '10px',
                        cursor: 'pointer',
                    }}
                >
                    <div className="dropzone">
                        <img src={UploadIcon} alt="upload-img" width={40} height={40} />
                        <div style={{ fontSize: '16px' }}>
                            <span style={{ color: '#FFE500' }}>Upload/Drag</span> your
                            receipt
                        </div>
                        <p style={{ color: '#FFFFFF' }}>
                            Supported format: png or jpg (Max size 5MB)
                        </p>
                    </div>
                </div>
            </DropZone>
            {uploadedFile ? (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        padding: '10px',
                        background: '#000000',
                        border: '1px solid #FFE500',
                        borderRadius: '10px',
                    }}
                >
                    {uploadedFile?.name}
                    <IconButton
                        aria-label="close"
                        onClick={clearFile}
                    >
                        <CloseIcon />
                    </IconButton>
                </div>
            ) : null}
        </>
    )
}

export default FileUpliadBox