import React from 'react'
import PreSaleHero from '../components/PreSale/PreSaleHero/PreSaleHero'
import { Helmet } from 'react-helmet'
import NavBar from '../components/Navbar'
import PreSaleComingSoon from '../components/PreSale/PreSaleComingSoon/PreSaleComingSoon'

const PreSale = () => {
  return (
    <>
      <Helmet>
        <title>PreSale | {process.env.REACT_APP_APP_NAME}</title>
      </Helmet>
      <NavBar />
      <PreSaleHero />
      <PreSaleComingSoon/>
    </>
  )
}

export default PreSale
