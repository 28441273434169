import React, { useState } from 'react'
import './login.css' // assuming the CSS above is saved in Login.css
import {
  Button,
  IconButton,
  InputAdornment,
  Link,
  TextField,
} from '@mui/material'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'

const Login = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [passwordVisible, setPasswordVisible] = useState(false)
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault()
    const payload = {
      username: email,
      password: password,
    }
    let formBody = Object.entries(payload)
      .map(
        ([key, value]) =>
          encodeURIComponent(key) + '=' + encodeURIComponent(value),
      )
      .join('&')
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: formBody,
    }
    try {
      const res = await fetch(
        `https://staking.polarizenetwork.com/api/v1/login/access-token`,
        requestOptions,
      )
      const data = await res.json();
      console.log("data-=-=", data)
      if (res.status !== 200) {
        throw new Error(data?.detail)
      }
      if (data?.access_token) {
        localStorage.setItem('accessToken', data?.access_token)
        localStorage.setItem('refreshToken', data?.refresh_token)
        localStorage.setItem('expirationTime', Date.now() + 3600 * 1000);
        navigate("/dashboard")
      }
    } catch (err) {
      console.log('err', err)
    }
  }

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible)
  }

  return (
    <div className="login-container">
      <div className="sub-container">
        <div>Email ID</div>
        <TextField
          type="email"
          placeholder="Enter your email address"
          fullWidth
          variant="outlined"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        // className="text-field"
        />
      </div>
      <div className="sub-container">
        <div htmlFor="password">Password</div>
        <TextField
          placeholder="Enter your password"
          type={passwordVisible ? 'text' : 'password'}
          fullWidth
          variant="outlined"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={togglePasswordVisibility} edge="end">
                  {passwordVisible ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        // className="text-field"
        />
      </div>

      <Link href="/retail/forgot-password" underline="hover" align="right">
        Forgot Password?
      </Link>
      <Button
        onClick={handleSubmit}
        variant="contained"
        fullWidth
        style={{ borderRadius: 50, minHeight: '48px', color: '#000' }}
        className="submit-button"
      >
        Login
      </Button>
    </div>
  )
}

export default Login
