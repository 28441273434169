import {
  Box,
  Button,
  Container,
  Grid,
  Typography,
  useTheme,
} from '@mui/material'
import React from 'react'
import { Helmet } from 'react-helmet'
import NavBar from '../components/Navbar'
import Bg from '../resources/images/Bg.png'
import Image from '../resources/images/Scholars_Adda.png'
import Ellipse from '../resources/images/Ellipse15.png'
import { Link } from 'react-router-dom'
import { ReactComponent as ArrowIcon } from '../resources/images/Arrow_Icon_Black.svg'

const ScholarsAdda = () => {
  const theme = useTheme()
  return (
    <>
      <Helmet>
        <title>Scholars Adda | {process.env.REACT_APP_APP_NAME}</title>
      </Helmet>
      <NavBar />

      <Container maxWidth="xl">
        <Box
          sx={{
            // marginTop: { xs: '25px', md: '30px' },
            minHeight: '516px',
            '::after': {
              content: "' '",
              backgroundImage: `url(${Bg})`,
              backgroundPosition: 'top center',
              backgroundRepeat: 'no-repeat',
              position: 'absolute',
              top: 0,
              left: 0,
              height: '100%',
              width: '100%',
              objectFit: 'cover',
              zIndex: -1,
            },
          }}
          position="relative"
        >
          <Box position="absolute" right={0} top={300}>
            <img src={Ellipse} alt="" />
          </Box>
          <Typography
            variant="xss"
            letterSpacing="0.7px"
            textAlign="center"
            display="block"
            maxWidth="1307px"
            margin="auto"
            mt="5px"
          >
            Scholars Adda
          </Typography>
          <Typography
            lineHeight={1.36}
            variant="h4"
            mt="10px"
            letterSpacing="0.44px"
            fontWeight={400}
            textAlign="center"
            display="block"
            maxWidth="1142px"
            mx="auto"
            sx={{ opacity: 0.8 }}
            px="20px"
          >
            One year into our Polarize Network journey, Scholars Adda has been
            the cornerstone, imparting value to over 10,000 users as the gateway
            to our ecosystem.
          </Typography>
          <Grid
            container
            pl={{ xs: '30px', sm: '87px', md: '203px' }}
            pr={{ xs: '30px', sm: '87px' }}
            mt="50px"
            pb="100px"
            columnSpacing="73px"
          >
            <Grid item xs={12} md={6}>
              <Typography
                variant="h7"
                fontWeight={400}
                lineHeight={1.95}
                letterSpacing="0.4px"
                sx={{ opacity: 0.6 }}
                display="block"
                maxWidth="527px"
              >
                Scholars Adda : Explore, Engage, Earn
              </Typography>
              <Typography
                variant="h7"
                fontWeight={400}
                lineHeight={1.95}
                letterSpacing="0.4px"
                sx={{ opacity: 0.6 }}
                display="block"
                mt="15px"
                maxWidth="527px"
              >
                Scholars Adda, a revolutionary Decentralized Web3 edutech
                platform, aims to redefine the educational landscape by infusing
                gamification principles into the learning process. In a world
                where traditional educational methods often fall short of
                engaging students, Scholars Adda provides an innovative solution
                that transforms learning into an interactive, rewarding, and
                enjoyable experience, specifically targeting the age group of 13
                to 30.By integrating gamification elements such as quizzes,
                exams, challenges, and leaderboards, the platform addresses the
                issues of passive learning, lack of motivation, and untapped
                student potential. Our platform combines a dynamic blend of
                cutting-edge technology, including blockchain, Web 3.0, machine
                learning, AI, and the metaverse through education, creating a
                welcoming environment for learners of all levels.
              </Typography>
              <Typography
                variant="h7"
                fontWeight={400}
                lineHeight={1.95}
                letterSpacing="0.4px"
                sx={{ opacity: 0.6 }}
                display="block"
                mt="15px"
                maxWidth="527px"
              >
                Scholars Adda not only captures learners' attention but also
                fosters curiosity and enthusiasm for knowledge acquisition
                within the 13 to 30 age range. The platform's commitment to
                making education an empowering and enjoyable experience is
                highlighted in its vision to shape futures and bridge worlds.
              </Typography>
              <Typography
                variant="h7"
                fontWeight={400}
                lineHeight={1.95}
                letterSpacing="0.4px"
                sx={{ opacity: 0.6 }}
                display="block"
                mt="15px"
                maxWidth="527px"
              >
                Pan India and global access to education, financial learning,
                and a significant contribution to India's GDP and the education
                sector are integral components of Scholars Adda's vision. The
                educational landscape is undergoing a significant
                transformation, and Scholars Adda emerges as a pioneer in this
                shift, specifically targeting the age group of 13 to 30.
              </Typography>
              <Typography
                variant="h7"
                fontWeight={400}
                lineHeight={1.95}
                letterSpacing="0.4px"
                sx={{ opacity: 0.6 }}
                display="block"
                mt="15px"
                maxWidth="527px"
              >
                In a conventional educational system where students often find
                themselves disengaged and uninspired, Scholars Adda envisions a
                future where learning is not merely a task but an exciting
                adventure filled with exploration and growth for individuals
                aged 13 to 30. By leveraging gamification principles, the
                platform seeks to break the monotony of traditional teaching
                methods, making education an empowering and enjoyable experience
                for students of all ages.
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} pt="43px">
              <img alt="" src={Image} />
              <Button
                variant="contained"
                sx={{
                  padding: '20px 22px',
                  borderRadius: '34px',
                  color: theme.palette.common.black,
                  fontFamily: 'Krona One',
                  fontSize: 12,
                  fontWeight: 400,
                  letterSpacing: '0.56px',
                  maxWidth: '294px',
                  width: '100%',
                  lineHeight: 1,
                  maxHeight: '57px',
                  mx: 'auto',
                  display: 'flex',
                  mt: '138px',
                }}
                onClick={() => {
                  window.open('https://scholarsadda.ai/')
                }}
              >
                Visit Website <ArrowIcon height="32px" width="32px" />
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  )
}

export default ScholarsAdda
