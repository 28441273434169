import React from 'react';
import '../Notification/Notification.css';
import { useNavigate } from 'react-router-dom';

const MyProfileModal = () => {
    const navigate = useNavigate();

    const handleLogout = () => {
        localStorage.clear();
        navigate("/retail/login");
    }
    return (
        <div className="profile-modal">
            <div className="modal-content">
                <p style={{ cursor: "pointer", margin: 0 }} onClick={handleLogout}>Logout</p>
            </div>
        </div>
    );
};

export default MyProfileModal;
