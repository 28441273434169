import * as React from 'react'
const Money = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={381}
    height={329}
    fill="none"
    {...props}
  >
    <path
      fill="#FFBC00"
      d="m35.85 258.299 2.3 10.6s5.9 6.5 28.8 5.8c24-.7 25.4-7.4 25.4-7.4l.6-9.8-57.1.8Z"
    />
    <path
      fill="#FFE506"
      d="M91.55 255.199c-5.4-4.6-23-5.3-25.5-5.4-18-.5-28.1 1.6-29.9 6.9-2.2 6.5 13.3 8.8 17 9.4 10.2 1.7 21 1.2 31-1 2.6-.6 5.3-1.3 7.1-3.3 2.7-2.7 2.3-4.9.3-6.6Z"
    />
    <path
      fill="#FFD300"
      d="M42.65 258.399c-2.4-2.5 3.5-5.8 19.1-6.1 15.6-.2 24.4 2.9 25.4 5.7 1.3 3.8-38.3 6.9-44.5.4Z"
    />
    <path
      fill="#FFBC00"
      d="m44.65 243.799 2.3 10.6s5.9 6.5 28.8 5.8c24-.7 25.4-7.4 25.4-7.4l.6-9.8-57.1.8Z"
    />
    <path
      fill="#FFE506"
      d="M100.35 240.699c-5.4-4.6-23-5.3-25.5-5.4-18-.5-28.1 1.6-29.9 6.9-2.2 6.5 13.3 8.8 17 9.4 10.2 1.7 21 1.2 31-1 2.6-.6 5.3-1.3 7.1-3.3 2.7-2.8 2.3-5 .3-6.6Z"
    />
    <path
      fill="#FFD300"
      d="M51.45 243.799c-2.4-2.5 3.5-5.8 19.1-6.1 15.6-.2 24.4 2.9 25.4 5.7 1.3 3.9-38.3 6.9-44.5.4Z"
    />
    <path
      fill="#FFBC00"
      d="m33.15 231.299 2.3 10.6s5.9 6.5 28.8 5.8c24-.7 25.4-7.4 25.4-7.4l.6-9.8-57.1.8Z"
    />
    <path
      fill="#FFE506"
      d="M88.85 228.199c-5.4-4.6-23-5.3-25.5-5.4-18-.5-28.1 1.6-29.9 6.9-2.2 6.5 13.3 8.8 17 9.4 10.2 1.7 21 1.2 31-1 2.6-.6 5.3-1.3 7.1-3.3 2.6-2.8 2.3-5 .3-6.6Z"
    />
    <path
      fill="#FFD300"
      d="M39.95 231.299c-2.4-2.5 3.5-5.8 19.1-6.1 15.6-.2 24.4 2.9 25.4 5.7 1.3 3.8-38.3 6.9-44.5.4Z"
    />
    <path
      fill="#FFBC00"
      d="m35.55 215.699 2.3 10.6s5.9 6.5 28.8 5.8c24-.7 25.4-7.4 25.4-7.4l.6-9.8-57.1.8Z"
    />
    <path
      fill="#FFE506"
      d="M91.25 212.599c-5.4-4.6-23-5.3-25.5-5.4-18-.5-28.1 1.6-29.9 6.9-2.2 6.5 13.3 8.8 17 9.4 10.2 1.7 21 1.2 31-1 2.6-.6 5.3-1.3 7.1-3.3 2.6-2.8 2.3-5 .3-6.6Z"
    />
    <path
      fill="#FFD300"
      d="M42.35 215.699c-2.4-2.5 3.5-5.8 19.1-6.1 15.6-.2 24.4 2.9 25.4 5.7 1.2 3.9-38.3 6.9-44.5.4Z"
    />
    <path
      fill="#FFBC00"
      d="m34.15 200.799 2.3 10.6s5.9 6.5 28.8 5.8c24-.7 25.4-7.4 25.4-7.4l.6-9.8-57.1.8Z"
    />
    <path
      fill="#FFE506"
      d="M89.85 197.699c-5.4-4.6-23-5.3-25.5-5.4-18-.5-28.1 1.6-29.9 6.9-2.2 6.5 13.3 8.8 17 9.4 10.2 1.7 21 1.2 31-1 2.6-.6 5.3-1.3 7.1-3.3 2.7-2.8 2.3-5 .3-6.6Z"
    />
    <path
      fill="#FFD300"
      d="M40.95 200.799c-2.4-2.5 3.5-5.8 19.1-6.1 15.6-.2 24.4 2.9 25.4 5.7 1.3 3.9-38.3 6.9-44.5.4Z"
    />
    <path
      fill="#FFBC00"
      d="m34.15 192.699 2.3 10.6s5.9 6.5 28.8 5.8c24-.7 25.4-7.4 25.4-7.4l.6-9.8-57.1.8Z"
    />
    <path
      fill="#FFE506"
      d="M89.85 189.599c-5.4-4.6-23-5.3-25.5-5.4-18-.5-28.1 1.6-29.9 6.9-2.2 6.5 13.3 8.8 17 9.4 10.2 1.7 21 1.2 31-1 2.6-.6 5.3-1.3 7.1-3.3 2.7-2.8 2.3-5 .3-6.6Z"
    />
    <path
      fill="#FFD300"
      d="M40.95 192.699c-2.4-2.5 3.5-5.8 19.1-6.1 15.6-.2 24.4 2.9 25.4 5.7 1.3 3.9-38.3 6.9-44.5.4Z"
    />
    <path
      fill="#FFBC00"
      d="m40.85 183.199 2.3 10.6s5.9 6.5 28.8 5.8c24-.7 25.4-7.4 25.4-7.4l.6-9.8-57.1.8Z"
    />
    <path
      fill="#FFE506"
      d="M96.55 180.099c-5.4-4.6-23-5.3-25.5-5.4-18-.5-28.1 1.6-29.9 6.9-2.2 6.5 13.3 8.8 17 9.4 10.2 1.7 21 1.2 31-1 2.6-.6 5.3-1.3 7.1-3.3 2.6-2.8 2.3-5 .3-6.6Z"
    />
    <path
      fill="#FFD300"
      d="M47.65 183.199c-2.4-2.5 3.5-5.8 19.1-6.1 15.6-.3 24.4 2.9 25.4 5.7 1.3 3.9-38.3 6.9-44.5.4Z"
    />
    <path
      fill="#FFBC00"
      d="m34.15 172.999 2.3 10.6s5.9 6.5 28.8 5.8c24-.7 25.4-7.4 25.4-7.4l.6-9.8-57.1.8Z"
    />
    <path
      fill="#FFE506"
      d="M89.85 169.899c-5.4-4.6-23-5.3-25.5-5.4-18-.5-28.1 1.6-29.9 6.9-2.2 6.5 13.3 8.8 17 9.4 10.2 1.7 21 1.2 31-1 2.6-.6 5.3-1.3 7.1-3.3 2.7-2.8 2.3-4.9.3-6.6Z"
    />
    <path
      fill="#FFD300"
      d="M40.95 173.099c-2.4-2.5 3.5-5.8 19.1-6.1 15.6-.2 24.4 2.9 25.4 5.7 1.3 3.8-38.3 6.9-44.5.4Z"
    />
    <path
      fill="#FFBC00"
      d="m34.85 162.499 2.3 10.6s5.9 6.5 28.8 5.8c24-.7 25.4-7.4 25.4-7.4l.6-9.8-57.1.8Z"
    />
    <path
      fill="#FFE506"
      d="M90.55 159.399c-5.4-4.6-23-5.3-25.5-5.4-18-.5-28.1 1.6-29.9 6.9-2.2 6.5 13.3 8.8 17 9.4 10.2 1.7 21 1.2 31-1 2.6-.6 5.3-1.3 7.1-3.3 2.6-2.8 2.3-4.9.3-6.6Z"
    />
    <path
      fill="#FFD300"
      d="M41.65 162.599c-2.4-2.5 3.5-5.8 19.1-6.1 15.6-.2 24.4 2.9 25.4 5.7 1.3 3.8-38.3 6.9-44.5.4Z"
    />
    <path
      fill="#FFBC00"
      d="m29.45 152.199 2.3 10.6s5.9 6.5 28.8 5.8c24-.7 25.4-7.4 25.4-7.4l.6-9.8-57.1.8Z"
    />
    <path
      fill="#FFE506"
      d="M85.15 149.099c-5.4-4.6-23-5.3-25.5-5.4-18-.5-28.1 1.6-29.9 6.9-2.2 6.5 13.3 8.8 17 9.4 10.2 1.7 21 1.2 31-1 2.6-.6 5.3-1.3 7.1-3.3 2.6-2.8 2.3-4.9.3-6.6Z"
    />
    <path
      fill="#FFD300"
      d="M36.25 152.299c-2.4-2.5 3.5-5.8 19.1-6.1 15.6-.2 24.4 2.9 25.4 5.7 1.3 3.8-38.3 6.9-44.5.4Z"
    />
    <path
      fill="#000"
      d="M342.051 305.699c-4.6 0-9 .2-13.1.7 15.4-7.1 24.3-15.6 24.3-24.7 0-25-67-45.3-149.7-45.3-82.7 0-149.7 20.3-149.7 45.3 0 1.7.3 3.5 1 5.2-4.1-.6-8.6-.9-13.3-.9-20.3 0-39.7 6-39.7 13.4s19.4 13.4 39.7 13.4c14.9 0 27.7-3.3 33.5-7.9 26.1 13.2 73.9 22.1 128.6 22.1 39.4 0 75.3-4.6 102-12.2-.2.4-.2.8-.2 1.2 0 5.7 18.4 12.2 38.7 12.2 20.3 0 36.7-4 36.7-12.2-.1-5.7-18.6-10.3-38.8-10.3Z"
      opacity={0.05}
    />
    <path
      fill="#FFDDAD"
      d="M329.35 285.599c-5.8 6.9-14.1 13.3-25.1 18.3-31 14.3-200.3 16.1-225.1-21.1-3.3-4.9-5.4-9.8-6.7-14.6-.7-2.5-1.1-5-1.3-7.5-2.3-28.1 23.1-50.8 23.1-50.8 0-.1 1.9-5.6 4.8-13.6 3-8.1 7.1-18.8 11.9-29.5 6.3-14.3 13.6-28.6 20.5-36.7 13.8-16.2 31.9-26.7 52.5-30.6v-.1c-1.6-2.3-3.3-4.8-5-7.3-8.8-12.8-19.1-27.9-27.8-40.9 1.3-5.2 3.6-8.8 6.1-11.3 2.4-2.4 5-3.7 7.2-4.5.3-.1.5-.2.8-.3-1-3-1.8-5.6-2.2-7.6 2.7-11.8 16.4-11.8 17.6-11.8 3.8-8.9 10.5-11.5 17.6-13.8 1.4-.5 2.8-1 4.2-1.4 8.4-3.1 28.3 8.8 31.7 20.2 13.4 3.4 18.7 8 18.3 13-1.7 3.6-3.4 7.5-5.2 11.4 6 1.6 14.4 4.7 13.6 11.1-2.1 4.6-14.9 22.4-25.1 36.4-.2.2-.4.5-.5.7-2.2 2.9-4.2 5.7-6 8.2-.3.5-.7.9-1 1.4 6.6 2.2 14.3 5.5 22.9 10.8 30.4 18.6 46.9 61.4 57.5 98 12.8 11.2 19.4 18.2 28.1 35.5 5.4 11.2 3.3 25.7-7.4 38.4Z"
    />
    <path
      fill="#FFD097"
      d="M329.35 285.599c-5.8 6.9-14.1 13.3-25.1 18.3-31 14.3-200.3 16.1-225.1-21.1-3.3-4.9-5.4-9.8-6.7-14.6 105.5 50.1 188.5-3.4 190.7-10.7 2.3-7.3-10.5-17.4-27.5-29.8 8.3-28.4 5.7-58.7-3.1-86.7-3.2-10.2-7.3-20.2-12-29.8-1.1-2.3-4.6-6.6-4.8-9.1-.4-7.5 32.5 9.8 35.2 11.4 30.4 18.6 46.9 61.4 57.5 98 12.8 11.2 19.4 18.2 28.1 35.5 5.6 11.4 3.5 25.9-7.2 38.6ZM191.65 91.499c-2.7 1-26.3-37.4-34.4-51.5 2.4-2.4 5-3.7 7.2-4.5l.8 1c-.1 0 29.7 53.8 26.4 55ZM203.25 97.999c-2.1 3.7-22.7-82.2-22.7-82.2 3.8-8.9 10.6-11.5 17.6-13.8 1.2 16.8 6.9 92.7 5.1 96Z"
    />
    <path
      fill="#FFC17E"
      d="M260.75 56.299c-2.1 4.6-14.9 22.4-25.1 36.4-.2.2-.4.5-.5.7-2.2 2.9-4.2 5.7-6 8.2-.3.5-.7.9-1 1.4l-16.6-2.7 22.5-79.4c13.4 3.4 18.7 8 18.3 13-1.7 3.6-3.4 7.5-5.2 11.4 6 1.5 14.4 4.6 13.6 11Z"
    />
    <path
      fill="#FFC17E"
      d="M329.35 285.599c-5.8 6.9-14.1 13.3-25.1 18.3-31 14.3-200.3 16.1-225.1-21.1-3.3-4.9-5.4-9.8-6.7-14.6 8.2 5.1 19.2 14 45.6 20 61.7 14 142.9 9.8 174-23.2 16.3-17.3-17.7-34.8-17.7-34.8s3.1-92.2-52.5-129.4c2.5.2 4.9.5 7.2.7-.3.5-.7.9-1 1.4 6.6 2.2 14.3 5.5 22.9 10.8 30.4 18.6 46.9 61.4 57.5 98 12.8 11.2 19.4 18.2 28.1 35.5 5.6 11.2 3.5 25.7-7.2 38.4Z"
    />
    <path
      fill="#FFE8C2"
      d="M171.85 113.599c-2.2-3-28.7 11-36.6 28.7-7.9 17.7-28.4 59.6-24.4 62.3 7.3 4.9 40.3-61.7 40.3-61.7s27.4-20.1 20.7-29.3ZM101.65 222.599c-1-1.1-2.8-1-3.7.3-3.9 5.4-14.9 21.6-13.7 31.2 1.5 11.7 48.1 22.8 55.9 18 7.8-4.9-38.9-27.7-38.9-27.7s5.9-15.9.4-21.8Z"
    />
    <path
      fill="#753908"
      d="M227.55 177.599c1.5 0 10.9-.1 12.4-.4l-.1 4.3c-.1 2.8-2.2 4.3-4.7 4.3h-8.4c-3 15.7-18 25.5-42.1 26.6 14.6 13.1 30.1 25.3 47.8 36.5-3.8 8.7-17.3 7.7-24.1 4.9l-35.8-40.9 2.4-6.5c18.9-.3 30.7-8 33.4-20.7h-26.6c-1.9 0-6.9.3-8.9.4l.1-4.3c.1-2.8 2.2-4.3 4.6-4.3h31.2c-1.3-14.9-15.7-18.5-36.6-18.5-1.2-2.8-1.2-5.5 0-8.3l55.4-.1c1.5 0 10.9-.3 12.4-.4l-.1 4.3c-.1 2.8-2.2 4.4-4.7 4.4h-24.4c9.5 3.2 15.5 8.9 16.4 18.6h.4v.1Z"
    />
    <path
      stroke="#753908"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={6}
      d="M179.65 97.5c12.3-2.7 42.9-.3 52.9 4.499M177.45 92.7c12.9-3.6 48.7-5.5 59.8-1.6"
    />
    <path
      stroke="#753908"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={6}
      d="M174.851 86.3c13.4-.5 51.4 5.4 61.3 11.8"
    />
    <path
      fill="#FFBC00"
      d="m8.65 287.799 2.3 10.6s5.9 6.5 28.8 5.8c24-.7 25.4-7.4 25.4-7.4l.6-9.8-57.1.8Z"
    />
    <path
      fill="#FFE506"
      d="M64.35 284.699c-5.4-4.6-23-5.3-25.5-5.4-18-.5-28.1 1.6-29.9 6.9-2.2 6.5 13.3 8.8 17 9.4 10.2 1.7 21 1.2 31-1 2.6-.6 5.3-1.3 7.1-3.3 2.6-2.8 2.3-4.9.3-6.6Z"
    />
    <path
      fill="#FFD300"
      d="M15.35 287.799c-2.4-2.5 3.5-5.8 19.1-6.1 15.6-.2 24.4 2.9 25.4 5.7 1.3 3.9-38.2 7-44.5.4Z"
    />
    <path
      fill="#FFBC00"
      d="m2.05 277.799 2.3 10.6s5.9 6.5 28.8 5.8c24-.7 25.4-7.4 25.4-7.4l.6-9.8-57.1.8Z"
    />
    <path
      fill="#FFE506"
      d="M57.75 274.699c-5.4-4.6-23-5.3-25.5-5.4-18-.5-28.1 1.6-29.9 6.9-2.2 6.5 13.3 8.8 17 9.4 10.2 1.7 21 1.2 31-1 2.6-.6 5.3-1.3 7.1-3.3 2.6-2.8 2.3-5 .3-6.6Z"
    />
    <path
      fill="#FFD300"
      d="M8.85 277.799c-2.4-2.5 3.5-5.8 19.1-6.1 15.6-.2 24.4 2.9 25.4 5.7 1.3 3.9-38.3 6.9-44.5.4Z"
    />
    <path
      fill="#FFBC00"
      d="m.15 267.499 2.3 10.6s5.9 6.5 28.8 5.8c24-.7 25.4-7.4 25.4-7.4l.6-9.8-57.1.8Z"
    />
    <path
      fill="#FFE506"
      d="M55.95 264.399c-5.4-4.6-23-5.3-25.5-5.4-18-.5-28.1 1.6-29.9 6.9-2.2 6.5 13.3 8.8 17 9.4 10.2 1.7 21 1.2 31-1 2.6-.6 5.3-1.3 7.1-3.3 2.6-2.7 2.2-4.9.3-6.6Z"
    />
    <path
      fill="#FFD300"
      d="M6.95 267.599c-2.4-2.5 3.5-5.8 19.1-6.1 15.6-.2 24.4 2.9 25.4 5.7 1.3 3.8-38.3 6.9-44.5.4Z"
    />
    <path
      fill="#FFBC00"
      d="m7.05 253.799 2.3 10.6s5.9 6.5 28.8 5.8c24-.7 25.4-7.4 25.4-7.4l.6-9.8-57.1.8Z"
    />
    <path
      fill="#FFE506"
      d="M62.75 250.699c-5.4-4.6-23-5.3-25.5-5.4-18-.5-28.1 1.6-29.9 6.9-2.2 6.5 13.3 8.8 17 9.4 10.2 1.7 21 1.2 31-1 2.6-.6 5.3-1.3 7.1-3.3 2.6-2.8 2.3-5 .3-6.6Z"
    />
    <path
      fill="#FFD300"
      d="M13.85 253.799c-2.4-2.5 3.5-5.8 19.1-6.1 15.6-.2 24.4 2.9 25.4 5.7 1.2 3.8-38.3 6.9-44.5.4Z"
    />
    <path
      fill="#FFBC00"
      d="m.45 242.999 2.3 10.6s5.9 6.5 28.8 5.8c24-.7 25.4-7.4 25.4-7.4l.6-9.8-57.1.8Z"
    />
    <path
      fill="#FFE506"
      d="M56.15 239.899c-5.4-4.6-23-5.3-25.5-5.4-18-.5-28.1 1.6-29.9 6.9-2.2 6.5 13.3 8.8 17 9.4 10.2 1.7 21 1.2 31-1 2.6-.6 5.3-1.3 7.1-3.3 2.6-2.8 2.3-4.9.3-6.6Z"
    />
    <path
      fill="#FFD300"
      d="M7.25 243.099c-2.4-2.5 3.5-5.8 19.1-6.1 15.6-.2 24.4 2.9 25.4 5.7 1.2 3.8-38.3 6.9-44.5.4Z"
    />
    <path
      fill="#FFBC00"
      d="m1.55 234.399 2.3 10.6s5.9 6.5 28.8 5.8c24-.7 25.4-7.4 25.4-7.4l.6-9.8-57.1.8Z"
    />
    <path
      fill="#FFE506"
      d="M57.25 231.299c-5.4-4.6-23-5.3-25.5-5.4-18-.5-28.1 1.6-29.9 6.9-2.2 6.5 13.3 8.8 17 9.4 10.2 1.7 21 1.2 31-1 2.6-.6 5.3-1.3 7.1-3.3 2.6-2.8 2.3-5 .3-6.6Z"
    />
    <path
      fill="#FFD300"
      d="M8.35 234.399c-2.4-2.5 3.5-5.8 19.1-6.1 15.6-.2 24.4 2.9 25.4 5.7 1.2 3.9-38.3 6.9-44.5.4Z"
    />
    <path
      fill="#FFBC00"
      d="m316.851 303.899 2.3 10.6s5.9 6.5 28.8 5.8c24-.7 25.4-7.4 25.4-7.4l.6-9.8-57.1.8Z"
    />
    <path
      fill="#FFE506"
      d="M372.55 300.799c-5.4-4.6-23-5.3-25.5-5.4-18-.5-28.1 1.6-29.9 6.9-2.2 6.5 13.3 8.8 17 9.4 10.2 1.7 21 1.2 31-1 2.6-.6 5.3-1.3 7.1-3.3 2.7-2.8 2.3-5 .3-6.6Z"
    />
    <path
      fill="#FFD300"
      d="M323.651 303.899c-2.4-2.5 3.5-5.8 19.1-6.1 15.6-.2 24.4 2.9 25.4 5.7 1.3 3.9-38.3 6.9-44.5.4Z"
    />
    <path
      fill="#FFBC00"
      d="m307.05 289.299 2.3 10.6s5.9 6.5 28.8 5.8c24-.7 25.4-7.4 25.4-7.4l.6-9.8-57.1.8Z"
    />
    <path
      fill="#FFE506"
      d="M362.75 286.199c-5.4-4.6-23-5.3-25.5-5.4-18-.5-28.101 1.6-29.901 6.9-2.2 6.5 13.3 8.8 17 9.4 10.2 1.7 21 1.2 31-1 2.6-.6 5.301-1.3 7.101-3.3 2.6-2.8 2.3-4.9.3-6.6Z"
    />
    <path
      fill="#FFD300"
      d="M313.849 289.299c-2.4-2.5 3.5-5.8 19.1-6.1 15.6-.2 24.4 2.9 25.4 5.7 1.3 3.9-38.3 6.9-44.5.4Z"
    />
    <path
      fill="#FFBC00"
      d="m320.55 274.799 2.3 10.6s5.9 6.5 28.8 5.8c24-.7 25.4-7.4 25.4-7.4l.6-9.8-57.1.8Z"
    />
    <path
      fill="#FFE506"
      d="M376.35 271.699c-5.4-4.6-23-5.3-25.5-5.4-18-.5-28.1 1.6-29.9 6.9-2.2 6.5 13.3 8.8 17 9.4 10.2 1.7 21 1.2 31-1 2.6-.6 5.3-1.3 7.1-3.3 2.6-2.8 2.2-5 .3-6.6Z"
    />
    <path
      fill="#FFD300"
      d="M327.349 274.799c-2.4-2.5 3.5-5.8 19.1-6.1 15.6-.2 24.4 2.9 25.4 5.7 1.3 3.8-38.3 6.9-44.5.4Z"
    />
    <path
      fill="#FFBC00"
      d="m303.95 265.299 2.3 10.6s5.9 6.5 28.8 5.8c24-.7 25.4-7.4 25.4-7.4l.6-9.8-57.1.8Z"
    />
    <path
      fill="#FFE506"
      d="M359.75 262.199c-5.4-4.6-23-5.3-25.5-5.4-18-.5-28.1 1.6-29.9 6.9-2.2 6.5 13.3 8.8 17 9.4 10.2 1.7 21 1.2 31-1 2.6-.6 5.3-1.3 7.1-3.3 2.6-2.8 2.201-5 .3-6.6Z"
    />
    <path
      fill="#FFD300"
      d="M310.751 265.299c-2.4-2.5 3.5-5.8 19.1-6.1 15.6-.2 24.4 2.9 25.4 5.7 1.3 3.9-38.3 6.9-44.5.4Z"
    />
    <path
      fill="#FFBC00"
      d="m304.65 251.399 2.3 10.6s5.9 6.5 28.8 5.8c24-.7 25.4-7.4 25.4-7.4l.6-9.8-57.1.8Z"
    />
    <path
      fill="#FFE506"
      d="M360.451 248.299c-5.4-4.6-23-5.3-25.5-5.4-18-.5-28.1 1.6-29.9 6.9-2.2 6.5 13.3 8.8 17 9.4 10.2 1.7 21 1.2 31-1 2.6-.6 5.3-1.3 7.1-3.3 2.6-2.8 2.2-5 .3-6.6Z"
    />
    <path
      fill="#FFD300"
      d="M311.45 251.399c-2.4-2.5 3.5-5.8 19.1-6.1 15.6-.2 24.4 2.9 25.4 5.7 1.3 3.9-38.3 6.9-44.5.4Z"
    />
    <path
      fill="#FFBC00"
      d="m305.65 239.199 2.3 10.6s5.9 6.5 28.8 5.8c24-.7 25.4-7.4 25.4-7.4l.6-9.8-57.1.8Z"
    />
    <path
      fill="#FFE506"
      d="M361.451 236.099c-5.4-4.6-23-5.3-25.5-5.4-18-.5-28.1 1.6-29.9 6.9-2.2 6.5 13.3 8.8 17 9.4 10.2 1.7 21 1.2 31-1 2.6-.6 5.3-1.3 7.1-3.3 2.6-2.8 2.2-4.9.3-6.6Z"
    />
    <path
      fill="#FFD300"
      d="M312.45 239.199c-2.4-2.5 3.5-5.8 19.1-6.1 15.6-.2 24.4 2.9 25.4 5.7 1.3 3.9-38.3 6.9-44.5.4Z"
    />
    <path
      fill="#FFBC00"
      d="m315.45 223.599 2.3 10.6s5.9 6.5 28.8 5.8c24-.7 25.4-7.4 25.4-7.4l.6-9.8-57.1.8Z"
    />
    <path
      fill="#FFE506"
      d="M371.25 220.499c-5.4-4.6-23-5.3-25.5-5.4-18-.5-28.1 1.6-29.9 6.9-2.2 6.5 13.3 8.8 17 9.4 10.2 1.7 21 1.2 31-1 2.6-.6 5.3-1.3 7.1-3.3 2.6-2.8 2.201-4.9.3-6.6Z"
    />
    <path
      fill="#FFD300"
      d="M322.251 223.699c-2.4-2.5 3.5-5.8 19.1-6.1 15.6-.2 24.4 2.9 25.4 5.7 1.3 3.8-38.2 6.9-44.5.4Z"
    />
  </svg>
)
export default Money
