// FileUploadInfo.js
import React, { useState } from 'react';
import InfoIcon from '@mui/icons-material/Info'; // Make sure you have @mui/icons-material installed
import './profile.css';
import Tooltip from './Tooltip';
import DownloadIcon from '@mui/icons-material/Download';
import { format } from 'date-fns';
import { downloadFile } from '../../../Services/User';
import { toast } from 'react-toastify';
import { Close } from '@mui/icons-material';

const PackageInformation = ({ userData }) => {
    const [showTooltip, setShowTooltip] = useState(false);

    const tooltipContent = [
        { label: 'Capital Investment', value: userData?.package?.[0]?.amount },
        { label: 'Allocated Equity', value: userData?.package?.[0]?.equity },
        { label: 'Reward Tokens', value: userData?.package?.[0]?.tokens },
        { label: 'Payment Mode', value: 'Cash' },
        { label: 'Payment Proof', value: 'Receipt' },
        { label: 'Referred By', value: userData?.full_name },
        { label: 'Investment Return', value: userData?.package?.[0]?.return_amount },
    ];

    const downloadPdf = async () => {
        try {
            if (!userData?.package?.[0]?.agreement) {
                toast.error("Agreement is not available", {
                    closeButton: <Close />
                });
                return;
            }
            const fileUrl = await downloadFile(userData?.package?.[0]?.agreement);
            var link = document.createElement('a');
            link.href = fileUrl?.url;
            link.download = 'Report.pdf';
            link.dispatchEvent(new MouseEvent('click'));
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <div>
            <div className="profile-form-container">
                <h2>Package Information</h2>
                <div className="form-row">
                    <div className="form-group">
                        <label>Joining Date</label>
                        <input
                            type="date"
                            name="time_created"
                            value={userData?.time_created ? format(userData?.time_created, "yyyy-MM-dd") : ""}
                        />
                    </div>
                    <div className="form-group info-input-container">
                        <label>Package Detail</label>
                        <input
                            type="text"
                            name="dateOfBirth"
                            value={"Package 1"}
                        />
                        <span className="info-toggle-icon">
                            <InfoIcon
                                className="info-icon"
                                onMouseEnter={() => setShowTooltip(true)}
                                onMouseLeave={() => setShowTooltip(false)}
                            />
                        </span>
                        {showTooltip && <Tooltip content={tooltipContent} />}
                    </div>
                    <div className="form-group">
                        <label>Agreement</label>
                        <div className='download-pdf-container'>
                            <div className='download-text'>
                                <img src='' />
                                <div>
                                    <h6>Report.pdf</h6>
                                    <p>1.23</p>
                                </div>
                            </div>
                            <DownloadIcon style={{ color: '#FFE500' }} onClick={downloadPdf} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PackageInformation;
