import React, { useState } from 'react'
import './forgetPassword.css' // assuming the CSS above is saved in Login.css
import VerifyEmail from './VerfiyEmail'
import VerifyOTP from './VerifyOTP'
import CreatePassword from '../CreatePassword'

const ForgetPassword = () => {
  const [step, setStep] = useState(1)

  const handleSubmit = (e) => {
    setStep((prev) => prev + 1)
  }

  return (
    <div className="forgot-container">
      {step === 1 && <VerifyEmail handleSubmit={handleSubmit} />}
      {step === 2 && <VerifyOTP handleSubmit={handleSubmit} />}
      {step === 3 && <CreatePassword handleSubmit={handleSubmit} />}
    </div>
  )
}

export default ForgetPassword
