// ----------------------------------------------------------------------

export function remToPx(value) {
  return Math.round(parseFloat(value) * 16)
}

export function pxToRem(value) {
  return `${value / 16}rem`
}

export function responsiveFontSizes({ xs, sm, md, lg }) {
  return {
    '@media (max-width:600px)': {
      fontSize: `${xs}px`,
    },
    '@media (min-width:600px)': {
      fontSize: `${sm}px`,
    },
    '@media (min-width:900px)': {
      fontSize: `${md}px`,
    },
    '@media (min-width:1200px)': {
      fontSize: `${lg}px`,
    },
  }
}

// ----------------------------------------------------------------------

const FONT_PRIMARY = 'Krona One, sans-serif' // Google Font
const FONT_SECONDARY = 'Poppins, sans-serif' // Local Font

const typography = {
  fontFamily: FONT_PRIMARY,
  fontFamilyAlt: FONT_SECONDARY,
  fontWeightRegular: 400,
  fontWeightMedium: 600,
  fontWeightBold: 700,
  h1: {
    fontFamily: FONT_SECONDARY,
    fontWeight: 400,
    lineHeight: 1.28,
    // fontSize: pxToRem(40),
    fontSize: '26px',
    ...responsiveFontSizes({ xs: 16, sm: 18, md: 22, lg: 26 }),
  },
  h2: {
    fontFamily: FONT_SECONDARY,
    fontWeight: 700,
    lineHeight: 1.29,
    // fontSize: pxToRem(32),
    fontSize: '42px',
    ...responsiveFontSizes({ xs: 26, sm: 32, md: 36, lg: 42 }),
  },
  h3: {
    fontFamily: FONT_SECONDARY,
    fontWeight: 400,
    lineHeight: 1.76,
    // fontSize: pxToRem(24),
    fontSize: '28px',
    ...responsiveFontSizes({ xs: 18, sm: 22, md: 24, lg: 28 }),
  },
  //changed
  h4: {
    fontFamily: FONT_SECONDARY,
    fontWeight: 400,
    lineHeight: 1.36,
    // fontSize: pxToRem(20),
    fontSize: '22px',
    ...responsiveFontSizes({ xs: 16, sm: 18, md: 20, lg: 22 }),
  },
  h5: {
    fontFamily: FONT_SECONDARY,
    fontWeight: 400,
    lineHeight: 3,
    // fontSize: pxToRem(18),
    fontSize: '18px',
    ...responsiveFontSizes({ xs: 14, sm: 16, md: 16, lg: 18 }),
  },
  h6: {
    fontFamily: FONT_SECONDARY,
    fontWeight: 500,
    lineHeight: 1.2,
    // fontSize: pxToRem(17),
    fontSize: '36px',
    ...responsiveFontSizes({ xs: 20, sm: 24, md: 30, lg: 36 }),
  },
  h7: {
    fontFamily: FONT_SECONDARY,
    fontWeight: 500,
    lineHeight: 1.4,
    // fontSize: pxToRem(17),
    fontSize: '20px',
    ...responsiveFontSizes({ xs: 14, sm: 16, md: 18, lg: 20 }),
  },
  h8: {
    fontFamily: FONT_SECONDARY,
    fontWeight: 700,
    lineHeight: 1.03,
    // fontSize: pxToRem(17),
    fontSize: '32px',
    ...responsiveFontSizes({ xs: 18, sm: 22, md: 26, lg: 32 }),
  },
  h9: {
    fontFamily: FONT_SECONDARY,
    fontWeight: 600,
    lineHeight: 2,
    // fontSize: pxToRem(17),
    fontSize: '16px',
    ...responsiveFontSizes({ xs: 13, sm: 14, md: 15, lg: 16 }),
  },
  h10: {
    fontFamily: FONT_SECONDARY,
    fontWeight: 400,
    lineHeight: 2,
    // fontSize: pxToRem(17),
    fontSize: '9.736px',
    ...responsiveFontSizes({ xs: 8, sm: 9, md: 9, lg: 9.736 }),
  },
  h11: {
    fontFamily: FONT_SECONDARY,
    fontWeight: 700,
    lineHeight: 1.36,
    // fontSize: pxToRem(17),
    fontSize: '17.286px',
    ...responsiveFontSizes({ xs: 4, sm: 8, md: 10, lg: 17.286 }),
  },

  h12: {
    fontFamily: FONT_SECONDARY,
    fontWeight: 600,
    lineHeight: 2,
    // fontSize: pxToRem(17),
    fontSize: '16px',
    ...responsiveFontSizes({ xs: 12, sm: 12, md: 14, lg: 16 }),
  },
  logo: {
    fontFamily: FONT_PRIMARY,
    fontWeight: 400,
    lineHeight: 1.5,
    // fontSize: pxToRem(17),
    fontSize: '30px',
    ...responsiveFontSizes({ xs: 18, sm: 22, md: 26, lg: 30 }),
  },
  xxl: {
    fontFamily: FONT_PRIMARY,
    fontWeight: 400,
    lineHeight: 1.1,
    // fontSize: pxToRem(17),
    fontSize: '85px',
    ...responsiveFontSizes({ xs: 40, sm: 46, md: 65, lg: 85 }),
  },
  xl: {
    fontFamily: FONT_PRIMARY,
    fontWeight: 400,
    lineHeight: 2,
    // fontSize: pxToRem(17),
    fontSize: '45px',
    ...responsiveFontSizes({ xs: 25, sm: 32, md: 40, lg: 45 }),
  },
  l: {
    fontFamily: FONT_PRIMARY,
    fontWeight: 400,
    lineHeight: 1.23,
    // fontSize: pxToRem(17),
    fontSize: '60px',
    ...responsiveFontSizes({ xs: 30, sm: 36, md: 42, lg: 60 }),
  },
  m: {
    fontFamily: FONT_PRIMARY,
    fontWeight: 400,
    lineHeight: 1.36,
    // fontSize: pxToRem(17),
    fontSize: '40px',
    ...responsiveFontSizes({ xs: 25, sm: 30, md: 35, lg: 40 }),
  },
  s: {
    fontFamily: FONT_SECONDARY,
    fontWeight: 400,
    lineHeight: 1.76,
    // fontSize: pxToRem(17),
    fontSize: '28px',
    ...responsiveFontSizes({ xs: 16, sm: 20, md: 24, lg: 28 }),
  },
  xs: {
    fontFamily: FONT_PRIMARY,
    fontWeight: 400,
    lineHeight: 1.13,
    // fontSize: pxToRem(17),
    fontSize: '65px',
    ...responsiveFontSizes({ xs: 45, sm: 52, md: 58, lg: 65 }),
  },
  xss: {
    fontFamily: FONT_PRIMARY,
    fontWeight: 400,
    lineHeight: 1.5,
    // fontSize: pxToRem(17),
    fontSize: '35px',
    ...responsiveFontSizes({ xs: 22, sm: 26, md: 30, lg: 35 }),
  },
  inter: {
    fontFamily: 'Inter, sans-serif',
    fontWeight: 500,
    lineHeight: 1.3,
    // fontSize: pxToRem(17),
    fontSize: '16px',
    ...responsiveFontSizes({ xs: 14, sm: 14, md: 16, lg: 16 }),
  },
  subtitle1: {
    fontFamily: FONT_SECONDARY,
    fontWeight: 400,
    lineHeight: 1.5,
    // fontSize: pxToRem(16),
    fontSize: '14px',
  },
  subtitle2: {
    fontWeight: 600,
    lineHeight: 22 / 14,
    // fontSize: pxToRem(14),
    fontSize: '14px',
  },
  //changed
  body1: {
    fontFamily: FONT_SECONDARY,
    lineHeight: 1.1,
    // fontSize: pxToRem(16),
    fontSize: '18px',
  },
  body2: {
    lineHeight: 22 / 14,
    // fontSize: pxToRem(14),
    fontSize: '14px',
    color: 'grey',
  },
  caption: {
    lineHeight: 1.5,
    // fontSize: pxToRem(12),
    fontSize: '12px',
    ...responsiveFontSizes({ xs: 8, sm: 9, md: 10, lg: 12 }),
  },
  overline: {
    fontWeight: 700,
    lineHeight: 1.5,
    // fontSize: pxToRem(12),
    fontSize: '12px',
    textTransform: 'uppercase',
  },
  //changed
  button: {
    fontWeight: 400,
    lineHeight: 24 / 14,
    // fontSize: pxToRem(14),
    fontSize: '18px',
    textTransform: 'capitalize',
  },
}

export default typography
