import { useState } from 'react'
import useRegisterUserStore from '../../../store/registerUser'
import { Button, TextField } from '@mui/material'
import './customFieldDialog.css'

const CustomFieldDialog = ({ onSubmit }) => {
  const { selectedPackage, addSelectedPackage } = useRegisterUserStore()
  const [customPackage, setCustomPackage] = useState({
    price: 300000,
    allocatedEquity: 0.06,
    rewardTokens: 40000,
    lockingPeriod: 3,
  })

  const handleSubmit = () => {
    addSelectedPackage(customPackage)
    onSubmit()
  }

  return (
    <div className="dialog-container">
      <div style={{ fontFamily: 'Krona One', fontSize: '24px' }}>
        Custom Field
      </div>
      <div className="dialog-sub-container">
        <div>Investment Amount</div>
        <TextField
          type="text"
          placeholder="Enter your email address"
          fullWidth
          variant="outlined"
          value={customPackage?.price}
          onChange={(e) =>
            setCustomPackage((prev) => ({ ...prev, price: e.target.value }))
          }
          className="text-field"
        />
      </div>
      <div className="dialog-sub-container">
        <div>Equity</div>
        <TextField
          type="text"
          placeholder="Enter your email address"
          fullWidth
          variant="outlined"
          value={customPackage?.allocatedEquity}
          onChange={(e) =>
            setCustomPackage((prev) => ({
              ...prev,
              allocatedEquity: e.target.value,
            }))
          }
          className="text-field"
        />
      </div>
      <div className="dialog-sub-container">
        <div>Reward Tokens</div>
        <TextField
          type="text"
          placeholder="Enter your email address"
          fullWidth
          variant="outlined"
          value={customPackage?.rewardTokens}
          onChange={(e) =>
            setCustomPackage((prev) => ({
              ...prev,
              rewardTokens: e.target.value,
            }))
          }
          className="text-field"
        />
      </div>
      <div className="dialog-sub-container">
        <div>Duration</div>
        <TextField
          type="text"
          placeholder="Enter your email address"
          fullWidth
          variant="outlined"
          value={customPackage?.lockingPeriod}
          onChange={(e) =>
            setCustomPackage((prev) => ({
              ...prev,
              lockingPeriod: e.target.value,
            }))
          }
          className="text-field"
        />
      </div>
      <Button
        onClick={handleSubmit}
        fullWidth
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
        style={{ borderRadius: 50, minHeight: '48px', color: '#000' }}
        className="submit-button"
      >
        Save changes
      </Button>
    </div>
  )
}
export default CustomFieldDialog
