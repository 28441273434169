import React, { useEffect, useState } from 'react'
import "./profile.css";
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { KCCInformationValidation } from '../../../utils/validation';
import FileUpliadBox from '../../../common/FileUpliadBox';
import { createKYC, getKYCDetails, updateKYC, uploadFile } from '../../../Services/User';
import { toast } from 'react-toastify';
import { Close } from '@mui/icons-material';

function KycInformation({ userData }) {
    const [initialValues, setInitialValues] = useState({
        name: userData?.full_name,
        aadhar_number: "",
        pancard: "",
        pancard_front: "",
        aadhar_front: "",
        aadhar_back: ""
    });

    function formatAadhar(aadharNumber) {
        const digits = aadharNumber.replace(/\D/g, '');
        const formatted = digits?.replace(/(\d{4})(\d{4})(\d{4})/, '$1 $2 $3');
        return formatted;
    }

    const onSubmit = async (values) => {
        try {
            const formData = new FormData();
            formData.append('file', values.pancard_front);
            const pancardRes = await uploadFile(formData, "pancard", "front");
            console.log(pancardRes)
            const aadharFormData = new FormData();
            aadharFormData.append('file', values.aadhar_front);
            const aadharFrontRes = await uploadFile(aadharFormData, "aadhar", "front");
            console.log(aadharFrontRes)
            const aadharBackFormData = new FormData();
            aadharBackFormData.append('file', values.aadhar_back);
            const aadharBackRes = await uploadFile(formData, "aadhar", "back");
            console.log(aadharBackRes)
            delete values?.passbook_photo;
            const formattedAadhar = formatAadhar(values?.aadhar_number);
            const payload = {
                pancard_front: pancardRes?.path,
                aadhar_front: aadharFrontRes?.path,
                aadhar_back: aadharBackRes?.path,
                aadhar_number: formattedAadhar,
                pancard: values?.pancard,
                name: userData?.full_name,
            }
            const createKYCRes = await updateKYC(payload);
            toast.success("KYC information submitted successfully.", {
                closeButton: <Close />
            });
        } catch (error) {
            console.log(error);
            toast.error(error, {
                closeButton: <Close />
            });
        }
    };

    const getBankingDetail = async () => {
        try {
            const data = await getKYCDetails();
            const aadhar_number = data?.[0]?.aadhar_number ? data?.[0]?.aadhar_number?.replace(/\s/g, "") : "";
            setInitialValues({
                ...initialValues,
                aadhar_number: aadhar_number,
                pancard: data?.[0]?.pancard,
                name: userData?.full_name,
                status: data?.[0]?.is_approved ? "approved" : data?.[0]?.is_rejected ? "rejected" : "pending"
            })
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getBankingDetail();
    }, [])


    return (
        <div>
            <div className="profile-form-container">
                <Formik
                    initialValues={initialValues}
                    validationSchema={KCCInformationValidation}
                    onSubmit={onSubmit}
                    enableReinitialize
                >
                    {({ setFieldValue, values, isSubmitting }) => (
                        <Form>
                            {console.log("values=-=", values)}
                            <div className='title-container'>
                                <h2 className='title'>KYC Information</h2>
                                <button type="submit" disabled={isSubmitting || !values?.aadhar_number || values?.status === "approved"} className='update-button'>{!isSubmitting ? values?.aadhar_number ? "Update" : values?.status === "approved" ? "Approved" : "Pending" : "Submitting..."}</button>
                            </div>
                            <div className="form-row" style={{ marginTop: "16px" }}>
                                <div className="form-group">
                                    <label>Aadhar Number</label>
                                    <Field type="text" name="aadhar_number" disabled={values?.status === "approved"} />
                                    <ErrorMessage name="aadhar_number" component="div" className="error" />
                                </div>
                                <div className="form-group">
                                    <label>Aadhar Card Front Photo</label>
                                    <FileUpliadBox uploadedFile={values?.aadhar_front} disabled={values?.status === "approved"} onDropHandler={(acceptedFiles) => setFieldValue('aadhar_front', acceptedFiles[0])} clearFile={() => setFieldValue('aadhar_front', null)} />
                                    <ErrorMessage name="aadhar_front" component="div" className="error" />
                                </div>
                                <div className="form-group">
                                    <label>Aadhar Card Back Photo</label>
                                    <FileUpliadBox uploadedFile={values?.aadhar_back} onDropHandler={(acceptedFiles) => setFieldValue('aadhar_back', acceptedFiles[0])} clearFile={() => setFieldValue('aadhar_back', null)} />
                                    <ErrorMessage name="aadhar_back" component="div" className="error" />
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-group">
                                    <label>Pan Card Number</label>
                                    <Field type="text" name="pancard" disabled={values?.status === "approved"} />
                                    <ErrorMessage name="pancard" component="div" className="error" />
                                </div>
                                <div className="form-group">
                                    <label>Pan Card Front Photo</label>
                                    <FileUpliadBox uploadedFile={values?.pancard_front} onDropHandler={(acceptedFiles) => setFieldValue('pancard_front', acceptedFiles[0])} clearFile={() => setFieldValue('pancard_front', null)} />
                                    <ErrorMessage name="pancard_front" component="div" className="error" />
                                </div>
                                <div className="form-group"></div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    )
}

export default KycInformation