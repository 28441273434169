import React from 'react'
import withdrawalIcon from "../../../resources/images/Retail/WithdrawalIcon.svg";
import "./withdrawal.css"

function Withdrawal() {
    return (
        <div className='withdrawal-container'>
            <img src={withdrawalIcon} alt='withdrawal icon' />
            <p>You will be able to withdraw funds once the investment period ends.</p>
        </div>
    )
}

export default Withdrawal