import { Divider } from '@mui/material'
import './packagesCard.css'

const PackageCard = ({ data, number, selectedPackage, onClick }) => {
  return (
    <div
      className="card-container"
      style={
        selectedPackage === number
          ? { border: '1px solid #FFE500' }
          : { border: '1px solid grey' }
      }
      onClick={onClick}
    >
      <div style={{ fontSize: '12px' }}>Package {number}</div>
      <div style={{ fontSize: '32px' }}>₹{data?.price}</div>
      <Divider />
      <ul className="card-description">
        <li>Allocated Equity : {data?.allocatedEquity}</li>
        <li>Reward Tokens : {data?.rewardTokens} PLR</li>
        <li>Locking Period : {data?.lockingPeriod} years</li>
        <li>Investment Return : ₹{data?.investmentReturn}</li>
      </ul>
    </div>
  )
}
export default PackageCard
