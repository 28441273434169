import axios from "axios";
import axiosInstance, { API_BASE_URL, axiosFileInstance } from "../utils/axios"

export const getUser = async () => {
    const res = await axiosInstance.get("/api/v1/users/me");
    return res?.data;
}

export const uploadFile = async (data, type, remark) => {
    const res = await axiosFileInstance.post(`/api/v1/aws/upload?type=${type}&remark=${remark}`, data);
    console.log(res);
    return res?.data;
}

export const getBankingDetails = async () => {
    const res = await axiosInstance.get("api/v1/users/me/banking_details");
    console.log(res);

    return res?.data;
}

export const createBanking = async (data) => {
    const res = await axiosInstance.post(`api/v1/users/me/banking_details`, data);
    console.log(res);
    return res?.data;
}

export const getKYCDetails = async () => {
    const res = await axiosInstance.get("api/v1/kyc/?skip=0&limit=100");
    console.log(res);

    return res?.data;
}

export const createKYC = async (data) => {
    const res = await axiosInstance.post(`api/v1/kyc/`, data);
    console.log(res);
    return res?.data;
}

export const updateKYC = async (data) => {
    const res = await axiosInstance.put(`api/v1/kyc/me`, data);
    console.log(res);
    return res?.data;
}

export const downloadFile = async (fileName) => {
    const res = await axiosInstance.post(`api/v1/aws/download?file_name=${fileName}`);
    console.log(res);
    return res?.data;
}

export const refreshToken = async () => {
    const token = localStorage.getItem('refreshToken'); // Example: storing the token in localStorage
    const res = await axios.post(`${API_BASE_URL}/api/v1/login/refresh-token?refresh_token=${token}`);
    console.log(res);
    return res?.data;
}

export const resetPassword = async (email) => {
    const res = await axiosInstance.post(`api/v1/reset-password`, email);
    console.log(res);
    return res?.data;
}