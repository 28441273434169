import { Box } from '@mui/material'
import React from 'react'
import { ReactComponent as LoaderIcon } from '../resources/images/Loader.svg'

const Loader = () => {
  return (
    <Box
      position="absolute"
      top="50%"
      left="50%"
      // width="100vw"
      // height="100vh"
      sx={{ transform: 'translate(-50%, -50%)' }}
    >
      <LoaderIcon />
    </Box>
  )
}

export default Loader
