import React, { useEffect, useState } from 'react'
import "./profile.css";
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { BankingInformationValidation } from '../../../utils/validation';
import FileUpliadBox from '../../../common/FileUpliadBox';
import { createBanking, getBankingDetails, uploadFile } from '../../../Services/User';
import { toast } from 'react-toastify';
import { Close } from '@mui/icons-material';

function BankingInformation({ userData }) {
    const [initialValues, setInitialValues] = useState({
        account_no: "",
        ifsc: "",
        bank_name: "",
        bank_holder_name: "",
        branch_name: "",
        passbook_photo: ""
    });
    const [isBankingCreated, setIsBankingCreated] = useState(true);



    const onSubmit = async (values) => {
        console.log('Form data', values);
        try {
            const formData = new FormData();
            formData.append('file', values.passbook_photo);
            const uploadedFileRes = await uploadFile(formData, "other", "front");
            console.log(uploadedFileRes)
            delete values?.passbook_photo;
            const payload = {
                ...values,
                passbook_photo: uploadedFileRes?.path
            }
            const createBankingRes = await createBanking(payload);
            console.log(createBankingRes)
            toast.success("Banking created Successfully.", {
                closeButton: <Close />
            });
        } catch (error) {
            console.log(error);
        }
    };

    const getBankingDetail = async () => {
        try {
            const data = await getBankingDetails();
            setInitialValues({
                ...initialValues,
                ...data
            })
        } catch (error) {
            console.log(error)
            if (error?.response?.data?.detail === "Banking details not found") {
                setIsBankingCreated(false);
            }
        }
    }

    useEffect(() => {
        getBankingDetail();
    }, [])


    return (
        <div>
            <div className="profile-form-container">
                <Formik
                    initialValues={initialValues}
                    validationSchema={BankingInformationValidation}
                    onSubmit={onSubmit}
                    enableReinitialize
                >
                    {({ setFieldValue, values, isSubmitting }) => (
                        <Form>
                            {console.log("values=-=", values)}
                            <div className='title-container'>
                                <h2 className='title'>Banking Information</h2>
                                <button type="submit" className='update-button'>{!isSubmitting ? isBankingCreated ? "Update" : "Pending" : "Submitting..."}</button>
                            </div>
                            <div className="form-row" style={{ marginTop: "16px" }}>
                                <div className="form-group">
                                    <label>Bank holder Name</label>
                                    <Field type="text" name="bank_holder_name" />
                                    <ErrorMessage name="bank_holder_name" component="div" className="error" />
                                </div>
                                <div className="form-group">
                                    <label>Account Number</label>
                                    <Field type="text" name="account_no" />
                                    <ErrorMessage name="account_no" component="div" className="error" />
                                </div>
                                <div className="form-group">
                                    <label>IFSC Code</label>
                                    <Field type="text" name="ifsc" />
                                    <ErrorMessage name="ifsc" component="div" className="error" />
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-group">
                                    <label>Passbook/Cheque Photo</label>
                                    <FileUpliadBox uploadedFile={values?.passbook_photo} onDropHandler={(acceptedFiles) => { setFieldValue('passbook_photo', acceptedFiles[0]); setIsBankingCreated(true) }} clearFile={() => setFieldValue('passbook_photo', null)} />
                                    <ErrorMessage name="passbook_photo" component="div" className="error" />
                                </div>
                                <div className="form-group">
                                    <label>Bank Name</label>
                                    <Field type="text" name="bank_name" />
                                    <ErrorMessage name="bank_name" component="div" className="error" />
                                </div>
                                <div className="form-group">
                                    <label>Bank Branch Name</label>
                                    <Field type="text" name="branch_name" />
                                    <ErrorMessage name="branch_name" component="div" className="error" />
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    )
}

export default BankingInformation