import React from 'react'

export const OpenEyeSvg = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="80"
        height="80"
        viewBox="0 0 80 80"
        fill="none"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M39.9997 30.5667C34.7805 30.5667 30.5664 34.7807 30.5664 40C30.5664 45.2193 34.7805 49.4333 39.9997 49.4333C45.219 49.4333 49.4331 45.2193 49.4331 40C49.4331 34.7807 45.219 30.5667 39.9997 30.5667ZM25.5664 40C25.5664 32.0193 32.019 25.5667 39.9997 25.5667C47.9805 25.5667 54.4331 32.0193 54.4331 40C54.4331 47.9807 47.9805 54.4333 39.9997 54.4333C32.019 54.4333 25.5664 47.9807 25.5664 40Z"
          fill="#FFE500"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M7.52446 29.9899C15.4942 17.462 27.1729 9.90002 39.9995 9.90002C52.8266 9.90002 64.5058 17.4627 72.4755 29.9915C74.2921 32.8393 75.1161 36.4872 75.1161 39.9834C75.1161 43.4795 74.2921 47.1274 72.4755 49.9752C64.5058 62.504 52.8266 70.0667 39.9995 70.0667C27.1723 70.0667 15.4932 62.504 7.52342 49.9752C5.70683 47.1274 4.88281 43.4795 4.88281 39.9834C4.88281 36.4865 5.70714 32.8379 7.52446 29.9899ZM39.9995 14.9C29.2933 14.9 19.0391 21.204 11.7422 32.6752L11.7401 32.6784C10.5582 34.5302 9.88281 37.1808 9.88281 39.9834C9.88281 42.7859 10.5582 45.4366 11.7401 47.2883L11.7422 47.2916C19.0391 58.7627 29.2933 65.0667 39.9995 65.0667C50.7057 65.0667 60.9598 58.7627 68.2568 47.2916L68.2588 47.2883C69.4408 45.4366 70.1161 42.7859 70.1161 39.9834C70.1161 37.1808 69.4408 34.5302 68.2588 32.6784L68.2568 32.6752C60.9598 21.204 50.7057 14.9 39.9995 14.9Z"
          fill="#FFE500"
        />
      </svg>
    </>
  )
}

export const CloseEyeSvg = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="80"
        height="80"
        viewBox="0 0 80 80"
        fill="none"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M39.9997 30.5667C34.7805 30.5667 30.5664 34.7808 30.5664 40C30.5664 41.7013 31.0128 43.2884 31.8007 44.6635L44.6632 31.801C43.2881 31.0131 41.701 30.5667 39.9997 30.5667ZM25.5664 40C25.5664 32.0193 32.019 25.5667 39.9997 25.5667C43.9924 25.5667 47.5867 27.1848 50.2008 29.7989C51.1771 30.7753 51.1771 32.3582 50.2008 33.3345L33.3342 50.2011C32.3579 51.1775 30.775 51.1775 29.7986 50.2011C27.1845 47.587 25.5664 43.9927 25.5664 40Z"
          fill="#FFE500"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M7.52446 30.0232C15.4942 17.4954 27.1729 9.93335 39.9995 9.93335C47.4982 9.93335 54.6844 12.5454 60.9049 17.2375C62.0072 18.0689 62.2268 19.6365 61.3954 20.7388C60.5639 21.8411 58.9963 22.0607 57.894 21.2292C52.4479 17.1213 46.3007 14.9333 39.9995 14.9333C29.2933 14.9333 19.0391 21.2374 11.7422 32.7085L11.7401 32.7118C10.5582 34.5635 9.88281 37.2142 9.88281 40.0167C9.88281 42.8192 10.5582 45.4699 11.7401 47.3216L11.7413 47.3234C14.2538 51.2672 17.1632 54.6435 20.2962 57.3379C21.3431 58.2382 21.4619 59.8166 20.5616 60.8635C19.6613 61.9103 18.0829 62.0291 17.036 61.1288C13.5024 58.0899 10.2785 54.3329 7.52436 50.01C5.70711 47.162 4.88281 43.5134 4.88281 40.0167C4.88281 36.5199 5.70714 32.8713 7.52446 30.0232Z"
          fill="#FFE500"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M65.2649 24.4853C66.3407 23.62 67.9144 23.7906 68.7798 24.8665C70.0594 26.4574 71.3165 28.1669 72.4759 29.9938C74.2915 32.8413 75.1151 36.4882 75.1151 39.9834C75.1151 43.4802 74.2908 47.1288 72.4735 49.9768C64.5037 62.5047 52.825 70.0667 39.9984 70.0667C35.5433 70.0667 31.1812 69.1247 27.095 67.4041C25.8224 66.8683 25.2252 65.4024 25.761 64.1299C26.2968 62.8574 27.7627 62.2602 29.0352 62.796C32.549 64.2754 36.2536 65.0667 39.9984 65.0667C50.7046 65.0667 60.9588 58.7627 68.2557 47.2916L68.2578 47.2883C69.4398 45.4366 70.1151 42.7859 70.1151 39.9834C70.1151 37.1809 69.4398 34.5302 68.2578 32.6785L68.2543 32.6729C67.2137 31.0332 66.0708 29.476 64.8837 28.0003C64.0184 26.9244 64.189 25.3507 65.2649 24.4853Z"
          fill="#FFE500"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M52.1526 39.8748C53.5104 40.1252 54.4082 41.4289 54.1578 42.7867C53.1032 48.5062 48.4721 53.1373 42.7526 54.1919C41.3948 54.4423 40.0911 53.5445 39.8407 52.1867C39.5903 50.8289 40.4881 49.5252 41.8459 49.2748C45.5264 48.5961 48.562 45.5605 49.2407 41.88C49.4911 40.5222 50.7948 39.6244 52.1526 39.8748Z"
          fill="#FFE500"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M33.3338 46.6656C34.3101 47.6419 34.3101 49.2248 33.3338 50.2011L8.43378 75.1011C7.45747 76.0774 5.87456 76.0774 4.89825 75.1011C3.92194 74.1248 3.92194 72.5419 4.89825 71.5656L29.7983 46.6656C30.7746 45.6893 32.3575 45.6893 33.3338 46.6656Z"
          fill="#FFE500"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M75.1014 4.89892C76.0777 5.87523 76.0777 7.45814 75.1014 8.43445L50.2014 33.3345C49.2251 34.3108 47.6421 34.3108 46.6658 33.3345C45.6895 32.3581 45.6895 30.7752 46.6658 29.7989L71.5658 4.89892C72.5421 3.92261 74.1251 3.92261 75.1014 4.89892Z"
          fill="#FFE500"
        />
      </svg>
    </>
  )
}

export const FinalLogoSvg = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        id="Layer_1"
        x="0px"
        y="0px"
        viewBox="0 0 1500 506.5"
        // style="enable-background:new 0 0 1500 506.5;"
      >
        {/* <style type="text/css">
          .st0{{ fill: '#FFFFFF' }}
          .st1{{ fillRule: 'evenodd', clipRule: 'evenodd', fill: '#FFFFFF' }}
        </style> */}
        <g>
          <g>
            <path
              class="st0"
              d="M452.7,265.6H408c-4.7,0-7.3,2.6-7.4,7.4l-0.1,40.5c0,11.7-12.5,18.9-20.3,18.9v-68.7    c0-12.9,5.9-18.7,18.7-18.6c20.7,0,33.4,0.1,54.2,0.1c10.1,0,18.4-8.3,18.4-18.4c0-10.1-8.3-18.4-18.4-18.4l-71.8-0.1    c-13.3,0-18-12.5-18-20.3H454c20.7,0.8,37.4,18,37.4,38.8S474,265.7,452.7,265.6L452.7,265.6L452.7,265.6z"
            />
            <path
              class="st1"
              d="M305.8,204v-19.2l0,0l0,0v0h0l71.4-71.4h-10.4l-67.8,67.8v105.1l6.9,6.9L305.8,204L305.8,204z M312.5,197.2    l44.2-44.2l-0.1-0.1l32.7-32.7l0,0h0l0,0l0,0h100.4l-7-7h-96l-74.2,74.2V197.2z M284.8,195.3l7.4-7.4l0,101.1l0,0l0,0v0h0    l32.7,32.7l0,0l43.9,43.9h-9.9l-74.1-74.1V195.3z M402.3,372.2L402.3,372.2l-46.2,0.1l0,0l0,0h0v0l-71.4-71.3v10.3l67.8,67.8    h105.1l6.8-6.8H402.3z M366.7,393.2l-7.5-7.5h101.2l0,0l0,0h0v0l13.5-13.5h0l62.9-62.9v10.4l-73.6,73.6H366.7z M543.6,302.5v19.9    h0l0,0v0h0l-70.8,70.8h9.8l68.4-68.4V221l-7.5-7.5L543.6,302.5L543.6,302.5z M564.6,311.2l-6.8,6.8v-99.8v0v0v0l0,0L543.6,204v0    L480.7,141h10.1l73.8,73.8V311.2z M473.9,134.3h19.6v0l0,0l0,0v0l71.1,71.1v-10.1L496.3,127H392l-7.3,7.3L473.9,134.3L473.9,134.3    z"
            />
          </g>
          <g>
            <path
              class="st0"
              d="M614.2,143.8h45.7c6.7,0,12.4,0.7,17.3,2.2c4.9,1.4,8.9,3.5,12.2,6.2c3.2,2.7,5.6,6,7.2,10    c1.6,3.9,2.4,8.3,2.4,13.3c0,4.6-0.8,8.9-2.3,12.8c-1.6,3.9-3.9,7.3-7.1,10.2c-3.2,2.9-7.2,5.1-12.1,6.7    c-4.9,1.6-10.7,2.4-17.3,2.4l-28.2-0.1v23h-17.6V143.8z M660.4,192.3c3.3,0,6.2-0.4,8.7-1.2c2.5-0.8,4.6-1.9,6.3-3.4    c1.7-1.5,2.9-3.2,3.8-5.3c0.8-2.1,1.2-4.4,1.2-7c0-5.1-1.7-9.1-5-12c-3.3-2.9-8.4-4.3-15-4.3h-28.6v33.2H660.4z"
            />
            <path
              class="st0"
              d="M712.2,197.7c0-4.7,1-9.1,2.9-13.2c1.9-4.1,4.6-7.7,8.2-10.8c3.5-3.1,7.8-5.5,12.8-7.3    c5-1.8,10.6-2.7,16.9-2.7c6.3,0,11.9,0.9,16.9,2.7c5,1.8,9.3,4.2,12.8,7.3c3.5,3.1,6.3,6.7,8.2,10.8c1.9,4.1,2.9,8.5,2.9,13.2    c0,4.7-1,9.1-2.9,13.2c-1.9,4.1-4.6,7.7-8.2,10.8c-3.5,3.1-7.8,5.5-12.8,7.3c-5,1.8-10.6,2.7-16.9,2.7c-6.3,0-11.9-0.9-16.9-2.7    c-5-1.8-9.3-4.2-12.8-7.3c-3.5-3.1-6.3-6.7-8.2-10.8C713.1,206.9,712.2,202.4,712.2,197.7z M729.4,197.7c0,2.6,0.5,5.1,1.6,7.5    c1.1,2.3,2.6,4.4,4.6,6.2c2,1.8,4.5,3.2,7.4,4.3c2.9,1.1,6.2,1.6,9.9,1.6c3.7,0,7-0.5,9.9-1.6c2.9-1.1,5.4-2.5,7.4-4.3    c2-1.8,3.6-3.9,4.6-6.2c1.1-2.3,1.6-4.8,1.6-7.5c0-2.6-0.5-5.1-1.6-7.5c-1.1-2.4-2.6-4.4-4.6-6.2c-2-1.8-4.5-3.2-7.4-4.3    c-2.9-1.1-6.2-1.6-9.9-1.6c-3.7,0-7,0.5-9.9,1.6c-2.9,1.1-5.4,2.5-7.4,4.3c-2,1.8-3.6,3.9-4.6,6.2    C729.9,192.6,729.4,195.1,729.4,197.7z"
            />
            <path class="st0" d="M810.7,138.4h16.4v92.1h-16.4V138.4z" />
            <path
              class="st0"
              d="M845,209.8c0-3.1,0.7-6,2.1-8.6c1.4-2.6,3.4-4.8,6.2-6.7c2.7-1.9,6.2-3.3,10.2-4.3c4.1-1,8.9-1.5,14.3-1.5    c4,0,8.3,0.3,12.7,0.9c4.5,0.6,9.1,1.5,13.8,2.7c-0.2-2.1-0.8-4-1.9-5.7c-1.1-1.8-2.6-3.3-4.5-4.6c-1.9-1.3-4.2-2.3-6.9-3    c-2.7-0.7-5.7-1.1-9.1-1.1c-1.9,0-3.9,0.1-6.1,0.3c-2.2,0.2-4.5,0.6-6.9,1c-2.4,0.4-4.7,1-7,1.7c-2.3,0.7-4.5,1.5-6.5,2.4l-5-13.5    c2.5-0.9,5.1-1.7,8-2.4c2.8-0.7,5.7-1.4,8.7-1.9c3-0.5,5.9-1,8.9-1.2c3-0.3,5.8-0.4,8.6-0.4c12.2,0,21.3,3.1,27.3,9.4    c6,6.3,8.9,15.8,8.9,28.7v28.5h-16.4v-6.2c-1.7,1-3.6,1.9-5.6,2.8c-2,0.9-4.2,1.7-6.6,2.3c-2.4,0.7-4.9,1.2-7.6,1.6    c-2.7,0.4-5.6,0.6-8.6,0.6c-5.1,0-9.6-0.6-13.5-1.7c-3.9-1.1-7.1-2.6-9.7-4.6c-2.6-2-4.5-4.3-5.9-6.9    C845.7,215.8,845,213,845,209.8z M862.3,209.3c0,1.1,0.3,2.1,0.8,3.1c0.6,1,1.4,1.9,2.7,2.6c1.2,0.8,2.8,1.4,4.7,1.8    c1.9,0.5,4.2,0.7,6.9,0.7c2.9,0,5.7-0.3,8.4-0.8c2.7-0.5,5.2-1.2,7.5-2.1c2.3-0.9,4.4-1.8,6.3-2.9c1.9-1.1,3.5-2.3,4.7-3.4v-2.9    c-2.5-0.7-5-1.3-7.5-1.8c-2.5-0.5-4.8-0.9-7-1.1c-2.2-0.3-4.3-0.5-6.3-0.6c-2-0.1-3.7-0.2-5.2-0.2c-3.1,0-5.6,0.2-7.7,0.6    c-2,0.4-3.7,1-4.9,1.6c-1.3,0.7-2.1,1.5-2.7,2.4C862.6,207.3,862.3,208.3,862.3,209.3z"
            />
            <path
              class="st0"
              d="M942.5,165H959v9.4c3.6-2.7,7.5-5,11.5-7c4-2,8-3.5,12-4.5l4.8,15c-6.3,0.6-11.9,2-16.7,4.2    c-4.8,2.2-8.7,5.3-11.7,9.3v39h-16.4V165z"
            />
            <path
              class="st0"
              d="M1008.2,156.1c-1.6,0-3.1-0.2-4.4-0.7c-1.3-0.5-2.4-1.1-3.4-1.9c-0.9-0.8-1.6-1.7-2.1-2.8    c-0.5-1.1-0.7-2.2-0.7-3.4c0-1.2,0.2-2.4,0.7-3.4c0.5-1.1,1.2-2,2.1-2.7c0.9-0.8,2-1.4,3.4-1.9c1.3-0.5,2.8-0.7,4.4-0.7    c1.7,0,3.2,0.2,4.5,0.7c1.3,0.5,2.4,1.1,3.4,1.9c0.9,0.8,1.6,1.7,2.1,2.7c0.5,1.1,0.7,2.2,0.7,3.4c0,1.2-0.2,2.3-0.7,3.4    c-0.5,1.1-1.2,2-2.1,2.8c-0.9,0.8-2,1.4-3.4,1.9C1011.3,155.8,1009.8,156.1,1008.2,156.1z M1000,165h16.4v65.5H1000V165z"
            />
            <path
              class="st0"
              d="M1069,178.7h-33.6V165h64l-40.4,51.8h38.2v13.7h-68.4L1069,178.7z"
            />
            <path
              class="st0"
              d="M1110,197.5c0-4.4,0.9-8.7,2.7-12.7c1.8-4.1,4.4-7.6,7.8-10.7c3.4-3.1,7.6-5.6,12.5-7.4    c4.9-1.8,10.6-2.8,16.8-2.8c6.3,0,11.9,0.9,16.9,2.8c5,1.9,9.3,4.4,12.8,7.7c3.5,3.3,6.2,7.1,8,11.5c1.9,4.4,2.8,9.1,2.8,14.2    c0,0.6,0,1.3,0,2c0,0.7-0.1,1.4-0.1,2h-61.9c0.7,2,1.8,3.8,3.4,5.4c1.6,1.6,3.5,3,5.9,4.2c2.4,1.2,5.1,2.1,8.2,2.7    c3.1,0.6,6.5,1,10.3,1c3.8,0,7.5-0.4,11.3-1.2c3.7-0.8,7.3-1.8,10.7-3l5.5,12.5c-2.1,1-4.3,1.9-6.5,2.6c-2.2,0.8-4.5,1.4-6.9,1.9    c-2.4,0.5-4.9,0.9-7.5,1.1c-2.6,0.3-5.4,0.4-8.2,0.4c-7.4,0-13.8-0.9-19.4-2.7c-5.5-1.8-10.2-4.2-13.9-7.3    c-3.7-3.1-6.5-6.7-8.4-10.8C1111,206.8,1110,202.3,1110,197.5z M1173,191.1c-0.6-1.8-1.5-3.5-2.7-5.1c-1.2-1.6-2.8-2.9-4.6-4.1    c-1.9-1.2-4-2.1-6.4-2.7c-2.4-0.7-5.1-1-8-1c-3.1,0-5.9,0.4-8.5,1.1c-2.6,0.7-4.8,1.6-6.7,2.8c-1.9,1.2-3.5,2.6-4.8,4.1    s-2.2,3.2-2.8,4.9H1173z"
            />
            <path
              class="st0"
              d="M614.1,280.1h17.6l54.1,28.3v-28.3h17.6v86.7h-17.6v-39.8L631.7,299v67.9h-17.6V280.1z"
            />
            <path
              class="st0"
              d="M722.7,333.9c0-4.4,0.9-8.7,2.7-12.7c1.8-4.1,4.4-7.6,7.8-10.7c3.4-3.1,7.6-5.6,12.5-7.4    c4.9-1.8,10.6-2.8,16.8-2.8c6.3,0,11.9,0.9,16.9,2.8c5,1.9,9.3,4.4,12.8,7.7c3.5,3.3,6.2,7.1,8,11.5c1.9,4.4,2.8,9.1,2.8,14.2    c0,0.6,0,1.3,0,2c0,0.7-0.1,1.4-0.1,2H741c0.7,2,1.8,3.8,3.4,5.4c1.6,1.6,3.5,3,5.9,4.2c2.4,1.2,5.1,2.1,8.2,2.7    c3.1,0.6,6.5,1,10.3,1c3.8,0,7.5-0.4,11.3-1.2c3.7-0.8,7.3-1.8,10.7-3l5.5,12.5c-2.1,1-4.3,1.9-6.5,2.6c-2.2,0.8-4.5,1.4-6.9,1.9    c-2.4,0.5-4.9,0.9-7.5,1.1c-2.6,0.3-5.4,0.4-8.2,0.4c-7.4,0-13.8-0.9-19.4-2.7c-5.5-1.8-10.2-4.2-13.9-7.3    c-3.7-3.1-6.5-6.7-8.4-10.8C723.6,343.2,722.7,338.7,722.7,333.9z M785.7,327.5c-0.6-1.8-1.5-3.5-2.7-5.1    c-1.2-1.6-2.8-2.9-4.6-4.1c-1.9-1.2-4-2.1-6.4-2.7c-2.4-0.7-5.1-1-8-1c-3.1,0-5.9,0.4-8.5,1.1c-2.6,0.7-4.8,1.6-6.7,2.8    c-1.9,1.2-3.5,2.6-4.8,4.1s-2.2,3.2-2.8,4.9H785.7z"
            />
            <path
              class="st0"
              d="M830.6,313.7h-15.8v-12.3h15.8V281h16.4v20.4h15.8v12.3h-15.8v53.2h-16.4V313.7z"
            />
            <path
              class="st0"
              d="M868.4,301.3h19l17,39.4l5,11l6.1-14.3l-16.2-36.1h16l7.9,19.4l7.9-19.4h16L931,337.5l6.2,14.3l5-11l17-39.4    h19l-31,65.5h-17.9l-6-12.7l-6,12.7h-17.9L868.4,301.3z"
            />
            <path
              class="st0"
              d="M985.8,334.1c0-4.7,1-9.1,2.9-13.2c1.9-4.1,4.6-7.7,8.2-10.8c3.5-3.1,7.8-5.5,12.8-7.3    c5-1.8,10.6-2.7,16.9-2.7c6.3,0,11.9,0.9,16.9,2.7c5,1.8,9.3,4.2,12.8,7.3c3.5,3.1,6.3,6.7,8.2,10.8c1.9,4.1,2.9,8.5,2.9,13.2    c0,4.7-1,9.1-2.9,13.2c-1.9,4.1-4.6,7.7-8.2,10.8c-3.5,3.1-7.8,5.5-12.8,7.3c-5,1.8-10.6,2.7-16.9,2.7c-6.3,0-11.9-0.9-16.9-2.7    c-5-1.8-9.3-4.2-12.8-7.3c-3.5-3.1-6.3-6.7-8.2-10.8C986.7,343.2,985.8,338.8,985.8,334.1z M1003,334.1c0,2.6,0.5,5.1,1.6,7.5    c1.1,2.3,2.6,4.4,4.6,6.2c2,1.8,4.5,3.2,7.4,4.3c2.9,1.1,6.2,1.6,9.9,1.6c3.7,0,7-0.5,9.9-1.6c2.9-1.1,5.4-2.5,7.4-4.3    c2-1.8,3.6-3.9,4.6-6.2c1.1-2.3,1.6-4.8,1.6-7.5c0-2.6-0.5-5.1-1.6-7.5c-1.1-2.4-2.6-4.4-4.6-6.2c-2-1.8-4.5-3.2-7.4-4.3    c-2.9-1.1-6.2-1.6-9.9-1.6c-3.7,0-7,0.5-9.9,1.6c-2.9,1.1-5.4,2.5-7.4,4.3c-2,1.8-3.6,3.9-4.6,6.2    C1003.5,329,1003,331.5,1003,334.1z"
            />
            <path
              class="st0"
              d="M1083.8,301.4h16.4v9.4c3.6-2.7,7.5-5,11.5-7c4-2,8-3.5,12-4.5l4.8,15c-6.3,0.6-11.9,2-16.7,4.2    c-4.8,2.2-8.7,5.3-11.7,9.3v39h-16.4V301.4z"
            />
            <path
              class="st0"
              d="M1140.9,274.8h16.4v54.4l30.6-27.8h25.1l-34.2,30.6l36.4,34.9h-24.6l-24.4-23.6l-8.9,8v15.6h-16.4V274.8z"
            />
          </g>
        </g>
      </svg>
    </>
  )
}

// export const CloseEyeSvg = () => {
//   return (
//     <>

//     </>
//   )
// }
