import React from 'react'
import "./footer.css";

function Footer() {
    return (
        <footer>
            <p>Email: legal@polarizenetwork.com    | Call: +918069200871 Monday To Friday 10AM to 6PM</p>
            <p><a href="/retail/term-condition">Terms & Conditions</a> | <a href="/retail/privacy-policy">Privacy Policy</a></p>
        </footer>
    )
}

export default Footer