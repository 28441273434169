export const fetchRequestInterceptor = () => {
  const { fetch: originalFetch } = window
  window.fetch = async (...args) => {
    let [resource, config] = args
    const accessToken = localStorage.getItem('accessToken')
    const expirationTime = localStorage.getItem('expiratinTime')
    const refreshToken = localStorage.getItem('refreshToken')
    console.log(
      'resource',
      resource,
      !resource?.includes('login'),
      !resource?.includes('open'),
    )
    const isTokenExpired = Date.now() > expirationTime
    if (!resource?.includes('login') && !resource?.includes('open')) {
      if (!refreshToken) {
        window.location.href = 'http://localhost:3000/retail/login'
      } else if (!accessToken || !expirationTime || isTokenExpired) {
        const res = await fetch(
          `https://staking.polarizenetwork.com/api/v1/login/refresh-token?refresh_token=${refreshToken}`,
          {
            method: 'POST',
          },
        )
        const data = await res.json()
        localStorage.setItem('accessToken', data?.access_token)
        localStorage.setItem('refreshToken', data?.refresh_token)
        localStorage.setItem('expirationTime', Date.now() + 60 * 1000)
      }
    }
    let response = await originalFetch(resource, config)
    return response
  }
}
