import { Button, IconButton, Tab, TextField } from '@mui/material'
import './paymentDialog.css'
import DropZone from '../../../common/DropZone'
import UploadIcon from '../../../resources/images/upload-01.png'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { useState } from 'react'
import Cash from './Cash'
import BankTransfer from './BankTranfer'
import CloseIcon from '@mui/icons-material/Close'
import useRegisterUserStore from '../../../store/registerUser'

const PaymentDialog = ({ onSubmit, onError }) => {
  const [value, setValue] = useState('1')
  const [uploadedFile, setUploadedFile] = useState([])

  const { userData, selectedPackage } = useRegisterUserStore()

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const onDropHandler = (acceptedFiles) => {
    setUploadedFile(acceptedFiles)
  }

  const handleSubmit = async () => {
    const formData = new FormData()

    const payload = {
      password: userData?.password,
      email: userData?.email,
      full_name: userData?.fullName,
      mobile_no: userData?.mobileNumber,
      referral_name: userData?.referralName,
      address: userData?.address,
      file: uploadedFile?.[0],
    }

    for (let key in payload) {
      formData.append(key, payload[key])
    }

    const requestOptions = {
      method: 'POST',
      body: formData,
    }
    try {
      const res = await fetch(
        `https://staking.polarizenetwork.com/api/v1/open?pakage_id=${selectedPackage?.id}&gender=${userData?.gender}&dob=${userData?.dob}`,
        requestOptions,
      )

      const data = await res.json()
      if (res.status !== 200) {
        throw new Error(data?.detail)
      }
      onSubmit()
    } catch (err) {
      onError(typeof err == 'string' ? err : 'Something went wrong')
    }
  }

  return (
    <div className="payment-container">
      <TabContext value={value}>
        <TabList onChange={handleChange} aria-label="lab API tabs example">
          <Tab label="Cash" value="1" style={{ width: '50%' }} />
          <Tab label="Bank Transfer" value="2" style={{ width: '50%' }} />
        </TabList>
        <TabPanel
          value="1"
          style={{
            marginTop: '40px',
            display: 'flex',
            flexDirection: 'column',
            gap: '30px',
          }}
        >
          <Cash />
        </TabPanel>
        <TabPanel value="2" style={{ marginTop: '40px' }}>
          <BankTransfer />
        </TabPanel>
        <div className="dialog-sub-container">
          <DropZone onDropHandler={onDropHandler}>
            <div
              style={{
                padding: '10px',
                background: '#000000',
                borderRadius: '10px',
                cursor: 'pointer',
              }}
            >
              <div className="dropzone">
                <img src={UploadIcon} alt="upload-img" width={40} height={40} />
                <div style={{ fontSize: '16px' }}>
                  <span style={{ color: '#FFE500' }}>Upload/Drag</span> your
                  receipt
                </div>
                <p style={{ color: '#FFFFFF' }}>
                  Supported format: png or jpg (Max size 5MB)
                </p>
              </div>
            </div>
          </DropZone>
          {uploadedFile?.length ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '10px',
                background: '#000000',
                border: '1px solid #FFE500',
                borderRadius: '10px',
              }}
            >
              {uploadedFile?.[0]?.name}
              <IconButton
                aria-label="close"
                onClick={() => setUploadedFile([])}
              >
                <CloseIcon />
              </IconButton>
            </div>
          ) : null}
          <Button
            onClick={handleSubmit}
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            style={{ borderRadius: 50, minHeight: '48px', color: '#000' }}
            className="submit-button"
          >
            Upload
          </Button>
        </div>
      </TabContext>
    </div>
  )
}
export default PaymentDialog
