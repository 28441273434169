import React, { useEffect, useState } from 'react'
import './dashboard.css'
import { LinearProgress, linearProgressClasses, styled } from '@mui/material';
import InvestmentChart from './InvestmentChart';
import Footer from '../Footer/Footer';
import { getUser } from '../../../Services/User';
import { differenceInYears, differenceInMonths, differenceInDays, addYears, addMonths, subDays, parseISO } from 'date-fns';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: theme.palette.mode === 'light' ? '#FFE500' : '#308fe8',
    },
}));

function DashboardComponent({ userData, isAdminVerified }) {
    return (
        <>
            {isAdminVerified ? <>
                <div className="dashboard">
                    <div className="card">
                        <h2>₹{userData?.capital_invested || 0}</h2>
                        <p>Capital Invested</p>
                        <p className='desc'>Money put into investments or business ventures to grow.</p>
                    </div>
                    <div className="card">
                        <h2>₹{userData?.portfolio_value || 0}</h2>
                        <p>Portfolio Value</p>
                        <p className='desc'>Total worth of all investments held in a portfolio.</p>
                    </div>
                    <div className="card">
                        <h2>{userData?.allocated_equity}%</h2>
                        <p>Allocated Equity</p>
                        <p className='desc'>Ownership percentage assigned to an investor or shareholder.</p>
                    </div>
                    <div className="card">
                        <h2>{userData?.total_tokens} PLR</h2>
                        <p>Total Tokens</p>
                        <p className='desc'>Overall number of digital or crypto tokens owned.</p>
                    </div>
                    <div className="card">
                        <h2>₹10</h2>
                        <p>Per Token Value</p>
                        <p className='desc'>Market price of a single digital or crypto token.</p>
                    </div>
                    {console.log(userData)}
                    {userData?.package?.map(item => (<div className="card lock-period">
                        <div className='progress-box'>
                            <h2>3 years</h2>
                            <div>
                                <BorderLinearProgress variant="determinate" value={item?.period?.percentageElapsed} />
                                <p style={{ margin: 5, textAlign: "end" }}>{`${item?.period?.yearsLeft}years ${item?.period?.monthsLeft}month`}</p>
                            </div>
                        </div>
                        <p>Locking Period</p>
                        <p className='desc'>Timeframe where investments cannot be sold or withdrawn.</p>
                    </div>))}
                </div>
                <InvestmentChart userData={userData} />
            </> : <div className='withdrawal-container'>
                <p>You are not verified from admin.</p>
            </div>}
        </>
    )
}

export default DashboardComponent