import {
  Box,
  Button,
  Container,
  Grid,
  Typography,
  useTheme,
} from '@mui/material'
import React from 'react'
import { Helmet } from 'react-helmet'
import NavBar from '../components/Navbar'
import Bg from '../resources/images/Bg.png'
import Image from '../resources/images/PLR_Exchange.png'
import Ellipse from '../resources/images/Ellipse15.png'
import { Link } from 'react-router-dom'
import { ReactComponent as ArrowIcon } from '../resources/images/Arrow_Icon_Black.svg'
import ComingSoonImage from '../resources/images/Coming_Soon_Image.svg'

const PLRExchnage = () => {
  const theme = useTheme()
  return (
    <>
      <Helmet>
        <title>Polarize Exchange | {process.env.REACT_APP_APP_NAME}</title>
      </Helmet>
      <NavBar />
      <Container maxWidth="xl">
        <Box
          sx={{
            // marginTop: { xs: '25px', md: '30px' },
            minHeight: '516px',
            '::after': {
              content: "' '",
              backgroundImage: `url(${Bg})`,
              backgroundPosition: 'top center',
              backgroundRepeat: 'no-repeat',
              position: 'absolute',
              top: 0,
              left: 0,
              height: '100%',
              width: '100%',
              objectFit: 'cover',
              zIndex: -1,
            },
          }}
          position="relative"
        >
          <Box position="absolute" right={0} top={300}>
            <img src={Ellipse} alt="" />
          </Box>
          <Typography
            variant="xss"
            letterSpacing="0.7px"
            textAlign="center"
            display="block"
            maxWidth="1307px"
            margin="auto"
            mt="5px"
          >
            Polarize Exchange
          </Typography>
          <Typography
            lineHeight={1.36}
            variant="h4"
            mt="10px"
            letterSpacing="0.44px"
            fontWeight={400}
            textAlign="center"
            display="block"
            maxWidth="1142px"
            mx="auto"
            sx={{ opacity: 0.8 }}
            px="20px"
          >
            Navigating the Crypto Markets.
          </Typography>
          <Grid
            container
            pl={{ xs: '30px', sm: '87px', md: '203px' }}
            pr={{ xs: '30px', sm: '87px' }}
            mt="50px"
            pb="100px"
            columnSpacing="73px"
          >
            <Grid item xs={12} md={6}>
              <Typography
                variant="h7"
                fontWeight={400}
                lineHeight={1.95}
                letterSpacing="0.4px"
                sx={{ opacity: 0.6 }}
                display="block"
                maxWidth="527px"
              >
                Embark on a journey into the dynamic world of cryptocurrency
                trading with Polarize Exchange, a pivotal platform in the Polarize
                Network ecosystem. Offering a seamless and secure environment,
                Polarize Exchange empowers users to explore various digital assets
                and engage in trading activities using the native PLR Token as a
                base currency. Benefit from reduced trading fees, exclusive
                promotions, and opportunities for staking, enhancing your
                overall investment strategy within the ever-evolving landscape
                of Web3 markets.
              </Typography>
              <Typography
                variant="h7"
                fontWeight={400}
                lineHeight={1.95}
                letterSpacing="0.4px"
                sx={{ opacity: 0.6 }}
                display="block"
                mt="15px"
                maxWidth="527px"
              >
                Polarize Exchange stands as a testament to Polarize Network's
                commitment to fostering financial innovation and inclusion.
                Whether you are a seasoned trader or a newcomer to the crypto
                space, our platform provides user-friendly features, advanced
                tools, and a supportive community. The integration of PLR Tokens
                as a fundamental element adds an extra layer of utility,
                creating a holistic trading experience that extends beyond
                conventional exchanges.
              </Typography>
              <Typography
                variant="h7"
                fontWeight={400}
                lineHeight={1.95}
                letterSpacing="0.4px"
                sx={{ opacity: 0.6 }}
                display="block"
                mt="15px"
                maxWidth="527px"
              >
                As part of the larger Polarize ecosystem, Polarize Exchange plays a
                crucial role in connecting users to diverse platforms, from
                education to gaming and e-commerce. Join us in navigating the
                crypto markets, where every trade contributes to the growth of a
                vibrant and interconnected community. Polarize Exchange invites you
                to be part of the future of decentralized finance, where trading
                becomes not just a transaction but a transformative journey.
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} position="relative" top={-81}>
              <img alt="" src={Image} />
              {/* <Button
                variant="contained"
                sx={{
                  padding: '20px 22px',
                  borderRadius: '34px',
                  color: theme.palette.common.black,
                  fontFamily: 'Krona One',
                  fontSize: 12,
                  fontWeight: 400,
                  letterSpacing: '0.56px',
                  maxWidth: '294px',
                  width: '100%',
                  lineHeight: 1,
                  maxHeight: '57px',
                  mx: 'auto',
                  display: 'flex',
                  mt: '15px',
                }}
                LinkComponent={Link}
                // to="/tokenomics"
              >
                Coming Soon <ArrowIcon height="32px" width="32px" />
              </Button> */}
              <Box>
                <img src={ComingSoonImage} alt="" style={{ margin: 'auto' }} />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  )
}

export default PLRExchnage
