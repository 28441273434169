import PropTypes from 'prop-types'
import { useMemo } from 'react'
// @mui
import { CssBaseline } from '@mui/material'
import {
  ThemeProvider as MUIThemeProvider,
  createTheme,
  StyledEngineProvider,
} from '@mui/material/styles'
//
import palette from './palette'
import shadows from './shadows'
import typography from './typography'
import GlobalStyles from './globalStyles'
import customShadows from './customShadows'

import image from '../resources/images/image.svg'

// ----------------------------------------------------------------------

const ThemeProvider = (props) => {
  const { children } = props

  const themeOptions = useMemo(
    () => ({
      palette,
      shape: { borderRadius: 6 },
      typography,
      shadows: shadows(),
      customShadows: customShadows(),
      breakpoints: {
        values: {
          xs: 0,
          sm: 600,
          md: 900,
          lg: 1200,
          xl: 1600,
        },
      },
    }),
    [],
  )

  const theme = createTheme(themeOptions)

  theme.components = {
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: 'transparent',
          boxShadow: 'none',
          paddingTop: '22.5px',
          paddingBottom: '22.5px',
          letterSpacing: '0.28px',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: 14,
          fontWeight: 400,
          lineHeight: '18px',
        },
        text: {
          padding: '12px',
          color: 'rgba(255, 255, 255, 0.20) !important',
          [theme.breakpoints.down(1350)]: {
            fontSize: '12px !important',
            padding: '12px 10px',
          },
          [theme.breakpoints.down(500)]: {
            fontSize: '10px !important',
            padding: '12px 8px',
            minWidth: 'unset',
          },
          ':hover': {
            backgroundColor: 'unset',
            color: `${theme.palette.primary.main} !important`,
          },
          '&.active': {
            color: `${theme.palette.primary.main} !important`,
          },
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          color: 'black',
          '&.active': {
            color: 'rgba(0,0,0,0.75)',
            textDecoration: 'underline',
          },
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          fontSize: 16,
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: theme.palette.primary.main,
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          padding: '0 !important',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderRadius: '20px',
          border: '1px solid #BDBDBD',
          background: 'rgba(255, 255, 255, 0.10)',
        },
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          backgroundColor: 'transparent',
          boxShadow: 'none',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          border: 'none',
          color: theme.palette.primary.main,
          fontFamily: 'Poppins',
          fontSize: 16,
          fontWeight: 500,
        },
        body: {
          fontFamily: 'Poppins',
          fontSize: 16,
          fontWeight: 400,
          borderBottom: '1px solid rgba(255, 255, 255, 0.20)',
          paddingTop: '40px',
          paddingBottom: '24px',
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontFamily: 'Krona One',
          fontSize: 14,
          fontWeight: 400,
          color: 'white',
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          backgroundColor: 'transparent',
          boxShadow: 'none',
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        expandIconWrapper: {
          color: 'white',
        },
        root: {
          padding: 0,
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: 0,
          paddingRight: '20px',
          // paddingLeft: '50px',
          // paddingRight: '50px',
          // [theme.breakpoints.down('md')]: {
          //   paddingLeft: '20px',
          //   paddingRight: '20px',
          // },
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          height: '16px',
          borderRadius: '18px',
          backgroundColor: '#3D3D3D',
        },
        bar: {
          borderRadius: '18px',
        },
      },
    },

    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          // border: '0.4px solid #FFE500',
          // borderTop: 'none',
          // border: '0.4px solid #FFE500',
          // borderRadius: '0',
          // background:
          //   'linear-gradient(91deg, rgba(0, 0, 0, 0.60) 23.49%, rgba(0, 0, 0, 0.00) 92.2%)',

          // borderImageSource:
          //   'linear-gradient(259.16deg, #FFE500 -19.26%, rgba(255, 229, 0, 0.0561049) 88.23%, rgba(255, 229, 0, 0) 116.17%)',

          // backdropFilter: 'blur(2px)',
          backgroundImage: `url(${image})`,
          backgroundPosition: 'bottom',
          backgroundSize: '100%',
          // objectFit: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundColor: '#000',
          // backgroundColor: 'unset',
          borderRadius: 0,

          // backdropFilter: 'blur(1000px)',
          margin: '0px !important',
        },
      },
    },
  }

  return (
    <StyledEngineProvider injectFirst>
      <MUIThemeProvider theme={theme}>
        <CssBaseline />
        <GlobalStyles />
        {children}
      </MUIThemeProvider>
    </StyledEngineProvider>
  )
}

ThemeProvider.propTypes = {
  children: PropTypes.node,
}

export default ThemeProvider
