import React from 'react'
import PersonalInformation from './PersonalInformation'
import BankingInformation from './BankingInformation'
import KycInformation from './KycInformation'
import PackageInformation from './PackageInformation'

function Profile({ userData, isAdminVerified }) {
    return (
        <div>
            <PersonalInformation userData={userData} />
            <BankingInformation userData={userData} />
            <KycInformation userData={userData} />
            <PackageInformation userData={userData} />
        </div>
    )
}

export default Profile