import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import './packages.css'
import PackageCard from '../../components/Packages/PackageCard'
import { useEffect, useState } from 'react'
import CloseIcon from '@mui/icons-material/Close'
import {
  DialogContent,
  DialogTitle,
  IconButton,
  Button,
  Dialog,
} from '@mui/material'
import useRegisterUserStore from '../../store/registerUser'
import { useNavigate } from 'react-router-dom'
import CustomFieldDialog from '../../components/Packages/CustomFieldDialog'
import PaymentDialog from '../../components/Packages/PaymentDialog'
import SuccessDialog from '../../components/Packages/SuccessDialog'

const packages = [
  {
    id: 1,
    price: 12500,
    allocatedEquity: 0.002,
    rewardTokens: 250,
    lockingPeriod: 3,
    investmentReturn: 25000,
  },
  {
    id: 2,
    price: 25000,
    allocatedEquity: 0.004,
    rewardTokens: 500,
    lockingPeriod: 3,
    investmentReturn: 50000,
  },
  {
    id: 3,
    price: 57500,
    allocatedEquity: 0.01,
    rewardTokens: 750,
    lockingPeriod: 3,
    investmentReturn: 120000,
  },
  {
    id: 4,
    price: 115000,
    allocatedEquity: 0.02,
    rewardTokens: 1500,
    lockingPeriod: 3,
    investmentReturn: 240000,
  },
  {
    id: 5,
    price: 240000,
    allocatedEquity: 0.04,
    rewardTokens: 4000,
    lockingPeriod: 3,
    investmentReturn: 500000,
  },
]
const Packages = () => {
  const [dialogBox, setDialogBox] = useState({
    open: false,
    type: '',
  })
  const navigate = useNavigate()
  const { userData, selectedPackage, addSelectedPackage, showSuccess } =
    useRegisterUserStore()

  const handleClose = () => {
    setDialogBox({ open: false, type: '' })
  }
  const goBack = () => {
    navigate('/retail/signup')
  }

  const handleSubmit = () => {
    setDialogBox({ open: true, type: 'payment' })
  }

  useEffect(() => {
    addSelectedPackage(packages[0])
  }, [])

  console.log({ userData, selectedPackage, showSuccess })

  return (
    <div className="packages-container">
      <div
        style={{ display: 'flex', gap: '4px', alignItems: 'center' }}
        className="primary_color"
        onClick={goBack}
      >
        <ArrowBackIosIcon />
        Back
      </div>
      <div className="all-packages">
        {packages?.map((e) => (
          <PackageCard
            data={e}
            key={e?.id}
            number={e?.id}
            selectedPackage={selectedPackage?.id}
            onClick={() => addSelectedPackage(e)}
          />
        ))}
      </div>
      <div className="button-container">
        <div className="button-sub-container">
          <Button
            onClick={() => setDialogBox({ open: true, type: 'custom-field' })}
            variant="contained"
            fullWidth
            style={{ borderRadius: 50, minHeight: '48px' }}
            className="custom-button"
          >
            Custom Field
          </Button>
          <Button
            onClick={handleSubmit}
            variant="contained"
            fullWidth
            style={{ borderRadius: 50, minHeight: '48px', color: '#000' }}
            className="pay-button"
          >
            Pay
          </Button>
        </div>
      </div>
      <Dialog
        open={dialogBox.open}
        maxWidth={'md'}
        style={{ fontFamily: 'Krona One' }}
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          {dialogBox.type === 'custom-field' && (
            <CustomFieldDialog onSubmit={handleSubmit} />
          )}
          {dialogBox.type === 'payment' && (
            <PaymentDialog
              onSubmit={() => setDialogBox({ type: 'success', open: true })}
              onError={(error) =>
                setDialogBox({ type: 'error', open: true, message: error })
              }
            />
          )}
          {dialogBox.type === 'success' && <SuccessDialog />}
          {dialogBox.type === 'error' && (
            <SuccessDialog error={true} message={dialogBox?.message} />
          )}
        </DialogContent>
      </Dialog>
    </div>
  )
}
export default Packages
