import React from 'react'
import Layout from '../Header/Layout'
import Footer from '../Footer/Footer'
import { Box } from '@mui/material'

function TermCondition() {
    return (
        <Layout>
            <Box sx={{ width: "100%", padding: { xs: "20px", md: "20px 80px" } }}>
                <h2>Terms & condition</h2>
                <ul style={{ display: 'flex', flexDirection: "column", gap: "20px" }}>
                    <li>Investment Duration: By investing, you agree that your investment amount will be locked for a period of 3 years from the date of investment.</li>
                    <li>Investment Recipient: Your investment will be made in Polarize Network Pvt. Ltd. (hereinafter referred to as "the Company").</li>
                    <li>No Withdrawal: You acknowledge that no withdrawal of the invested amount will be permitted before the completion of the 3-year investment period.</li>
                    <li>Return on Investment: Returns on your investment will be disbursed after the completion of the 3-year investment period. The mode and frequency of return disbursement will be at the discretion of the Company.</li>
                    <li>Risk Acknowledgement: You understand and accept that investment in the Company carries inherent risks, including but not limited to market fluctuations, business performance, and regulatory changes. The Company does not guarantee any extra returns than commitment on the token or equity & the invested capital.</li>
                    <li>Investment Amount: You agree to invest the specified amount as indicated in the investment agreement. Any subsequent changes to the investment amount must be mutually agreed upon in writing by both parties.</li>
                    <li>Termination: This investment agreement shall remain in effect for the duration of the investment period unless terminated earlier by mutual agreement or due to breach of terms by either party.</li>
                    <li>Governing Law: This agreement shall be governed by and construed in accordance with the laws of Aurangabad, Maharashtra, and any disputes arising out of or in connection with this agreement shall be subject to the exclusive jurisdiction of the courts of Aurangabad, Maharashtra.</li>
                    <li>Amendments: Any amendments or modifications to this agreement shall be valid only if made in writing and duly signed by authorized representatives of both parties.</li>
                    <li>Confidentiality: Both parties agree to maintain the confidentiality of all information exchanged during this investment agreement, unless required by law to disclose such information.</li>
                    <li>Indemnification: You agree to indemnify and hold harmless the Company, its officers, directors, employees, and agents from any claims, losses, damages, liabilities, or expenses arising out of or in connection with your investment or any breach of this agreement by you.</li>
                    <p>By investing in Polarize Network Pvt. Ltd. under the terms and conditions specified above, you acknowledge that you have read, understood, and agreed to be bound by the terms of this agreement.</p>
                </ul>
            </Box>
            <Footer />
        </Layout>
    )
}

export default TermCondition