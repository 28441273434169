import * as Yup from 'yup';

export const BankingInformationValidation = Yup.object({
    account_no: Yup.string().required('Account Number is Required'),
    ifsc: Yup.string().required('IFSC code is Required'),
    bank_name: Yup.string().required('Bank name is Required'),
    bank_holder_name: Yup.string().required('Bank holder name is Required'),
    branch_name: Yup.string().required('Bank branch name is Required'),
    passbook_photo: Yup.string().required('Passbook photo is Required'),
});

export const KCCInformationValidation = Yup.object({
    aadhar_number: Yup.string().required('Aadhar Number is Required').matches(/^\d{12}$/, 'Aadhar must be exactly 12 digits'),
    pancard: Yup.string().required('Pancard number is Required'),
    pancard_front: Yup.string().required('Pancard front image is Required'),
    aadhar_front: Yup.string().required('Aadhar card front image is Required'),
    aadhar_back: Yup.string().required('Aadhar card back image is Required'),
});