import { format } from 'date-fns'
import { create } from 'zustand'

const useRegisterUserStore = create((set) => ({
  userData: {
    fullName: '',
    gender: 'Male',
    dob: format(new Date(), 'yyyy-MM-dd'),
    password: '',
    email: '',
    address: '',
    mobileNumber: '',
    referralName: '',
  },
  selectedPackage: {},
  recieverName: '',
  bankDetails: {
    bankName: '',
    bankHolderName: '',
    accountNumber: '',
    ifsc: '',
  },
  addUserData: (user) => set((state) => ({ userData: user })),
  addSelectedPackage: (pack) => set((state) => ({ selectedPackage: pack })),
  addRecieverName: (name) => set((state) => ({ recieverName: name })),
  addBankDetails: (details) => set((state) => ({ bankDetails: details })),
}))

export default useRegisterUserStore
