import { Box, Tab } from '@mui/material'
import Layout from '../../../components/Retail/Header/Layout';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import DashboardComponent from '../../../components/Retail/Dashboard/Dashboard';
import Footer from '../../../components/Retail/Footer/Footer';
import Withdrawal from '../../../components/Retail/Withdrawal/Withdrawal';
import Profile from '../../../components/Retail/Profile/Profile';
import { differenceInYears, differenceInMonths, differenceInDays, addYears, addMonths, subDays, parseISO } from 'date-fns';
import { getUser } from '../../../Services/User';
import { Close } from '@mui/icons-material';
import { toast } from 'react-toastify';

const Dashboard = () => {
  const navigate = useNavigate()
  const [value, setValue] = useState('1')
  const [userData, setUserData] = useState();
  const [isAdminVerified, setIsAdminVerified] = useState(true);

  const calculateLockingPeriod = (startDate, today) => {
    const endDate = addYears(startDate, 3); // Adding 3 years to the start date
    const yearsLeft = differenceInYears(endDate, today);
    const adjustedEndDateAfterYears = addYears(today, yearsLeft);
    const monthsLeft = differenceInMonths(endDate, adjustedEndDateAfterYears);
    const adjustedEndDateAfterMonths = addMonths(adjustedEndDateAfterYears, monthsLeft);
    const daysLeft = differenceInDays(endDate, adjustedEndDateAfterMonths);

    const totalLockingPeriodDays = differenceInDays(endDate, startDate);
    const daysPassed = differenceInDays(today, startDate);
    const percentageElapsed = (daysPassed / totalLockingPeriodDays) * 100;
    return { yearsLeft, monthsLeft, daysLeft, percentageElapsed };
  };

  const getUserData = async () => {
    try {
      const data = await getUser();
      if (data?.admin_verified) {
        const today = new Date();
        const filteredData = data?.package?.map(item => {
          // const startDate = parseISO("2023-07-12");
          const startDate = parseISO(item?.time_created);
          const period = calculateLockingPeriod(startDate, today);
          return {
            ...item,
            period
          }
        })
        setUserData({ ...data, package: filteredData });
        localStorage.setItem("username", data?.full_name);
      } else {
        setIsAdminVerified(false);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getUserData();
  }, [])

  useEffect(() => {
    if (!isAdminVerified) {
      navigate("/retail/login");
      toast.error("You are not verified from admin.", {
        closeButton: <Close />
      });
      return;
    }
  }, [isAdminVerified])

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }
  return (
    <Layout userData={userData}>
      <Box sx={{ width: "100%", padding: { sm: "20px 5px", md: "20px 40px" } }}>
        <TabContext value={value}>
          <TabList
            onChange={handleChange}
            aria-label="lab API tabs example"
          >
            <Tab label="Dashboard" value="1" sx={{ width: { sm: "33%", md: "50%" } }} />
            <Tab label="Profile" value="2" sx={{ width: { sm: "33%", md: "50%" } }} />
            <Tab label="Withdrawal" value="3" sx={{ width: { sm: "33%", md: "50%" } }} />
          </TabList>
          <TabPanel value="1" style={{ marginTop: '40px' }}>
            <DashboardComponent userData={userData} isAdminVerified={isAdminVerified} />
          </TabPanel>
          <TabPanel value="2" style={{ marginTop: '40px' }}>
            <Profile userData={userData} isAdminVerified={isAdminVerified} />
          </TabPanel>
          <TabPanel value="3" style={{ marginTop: '40px' }}>
            <Withdrawal />
          </TabPanel>
        </TabContext>
      </Box>
      <Footer />
    </Layout>
  );
}

export default Dashboard
