import React from 'react';
import './Notification.css';

const NotificationModal = ({ notifications, onClose }) => {
    return (
        <div className="notification-modal">
            <div className="modal-content">
                <button className="close-btn" onClick={onClose}>X</button>
                {notifications?.length ? notifications.map((notification, index) => (
                    <div key={index} className="notification">
                        <p>{notification}</p>
                        <span>6h ago</span>
                    </div>
                )) : <h4 className='no-notification-found'>No Notification Found</h4>}
            </div>
        </div>
    );
};

export default NotificationModal;
