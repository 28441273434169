import React from 'react'
import Styles from './PreSaleHero.module.scss'

const PreSaleHero = () => {
  return (
    <>
      <div className={Styles.psh}>
        <div className={Styles.psh_subtitle}>
          <div className="hidden">
            <div data-aos="fade-up">
              Welcome to
              <span className="primary_color"> PLR Token </span>
              Private Sale!
            </div>
          </div>
        </div>
        <div className={Styles.psh_title}>
          <div className="hidden">
            <div data-aos="fade-up"> Connecting your world,</div>
          </div>

          <div className="hidden">
            <div data-aos="fade-up">
              <span> one service at a time</span>
            </div>
          </div>
        </div>
        <div className={Styles.psh_desc}>
          <div className="hidden">
            <div data-aos="fade-up">
              {' '}
              From Edutech to Gaming, we're your go-to for innovation with a
              dash of Chhatrapati Shivaji Maharaj's legacy and the power of the
              octagon. Join us – where tradition meets tech, and your needs take
              center stage!
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default PreSaleHero
