import { TextField } from '@mui/material'
import { useState } from 'react'
import CodeIcon from '../../../../resources/images/Retail/QrCode.jpg'
import useRegisterUserStore from '../../../../store/registerUser'

const BankTransfer = () => {
  const { bankDetails, addBankDetails } = useRegisterUserStore()
  return (
    <div className='bank-transfer-container'>
      <div className='qrcode-container'>
        <img src={"https://portal.getepay.in:8443/getepayPortal/apis/showQrCode?qrCode=SLnjuK3e3XsBvIb03kDeY3zqnc9i2OzY7fAHeiYcbzj9zPMzrwsDvtv8nvZywtLV"} alt="code" />
        <small>UPI ID : merchant911649.augp@aubank</small>
      </div>
      <div className='text-container'>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
          }}
        >
          <div>Bank Name</div>
          <TextField
            type="text"
            placeholder="Enter your Bank Name"
            fullWidth
            variant="outlined"
            value={"Au Small Finance Bank"}
            disabled
            onChange={(e) =>
              addBankDetails((prev) => ({ ...prev, bankName: e.target.value }))
            }
          />
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
          }}
        >
          <div>Bank Holder Name</div>
          <TextField
            type="text"
            placeholder="Enter your Bank Holder Name"
            fullWidth
            variant="outlined"
            value={"Polarize Network Pvt. Ltd."}
            disabled
            onChange={(e) =>
              addBankDetails((prev) => ({
                ...prev,
                bankHolderName: e.target.value,
              }))
            }
          />
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
          }}
        >
          <div>Account Number</div>
          <TextField
            type="text"
            placeholder="Enter your Account Number"
            fullWidth
            variant="outlined"
            value={"2302235747183467"}
            disabled
            onChange={(e) =>
              addBankDetails((prev) => ({
                ...prev,
                accountNumber: e.target.value,
              }))
            }
          />
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
          }}
        >
          <div>IFSC Code</div>
          <TextField
            type="text"
            placeholder="Enter your IFSC Code"
            fullWidth
            variant="outlined"
            value={"AUBL0002357"}
            disabled
            onChange={(e) =>
              addBankDetails((prev) => ({
                ...prev,
                ifsc: e.target.value,
              }))
            }
          />
        </div>
      </div>
    </div>
  )
}

export default BankTransfer
