import React, { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'

const DropZone = ({ children, onDropHandler, disabled }) => {
  const onDrop = useCallback((acceptedFiles) => {
    // Do something with the files
    onDropHandler(acceptedFiles)
  }, [])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} disabled={disabled} />
      {children}
    </div>
  )
}
export default DropZone
