import {
  AppBar,
  Box,
  Button,
  Container,
  CssBaseline,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
  Toolbar,
  Typography,
  useTheme,
} from '@mui/material'
import { useState } from 'react'
import MenuIcon from '@mui/icons-material/Menu'
import { NavLink, useNavigate } from 'react-router-dom'
import Logo from '../resources/images/Logo.svg'
import LogoFinal from '../resources/images/Polarize_Logo_Final.svg'
import { ReactComponent as DiscordIcon } from '../resources/images/Discord_Icon_Alt.svg'
import { ReactComponent as TwitterIcon } from '../resources/images/Twitter_Alt_Yellow.svg'
import { FinalLogoSvg } from './HomePage/SVGS/SvgBtns'

const drawerWidth = 240
const navItems = [
  { title: 'Home', link: '/' },
  { title: 'Access Platform', link: '/access-platform' },
  { title: 'About Us', link: '/about-us' },
  { title: 'Tokenomics', link: '/Tokenomics' },
  { title: 'PreSale', link: '/presale' },
  { title: 'Careers', link: '/careers' },
  { title: 'Contact Us', link: '/contact-us' },
  // { title: 'Stalking', link: '/stalking' },
  // { title: 'How it Works', link: '/how-it-works' },
  // { title: 'Features', link: '/features' },
  // { title: `FAQ's`, link: '/faqs' },
  // { title: 'Blogs', link: '/blogs' },
]

function NavBar() {
  const [mobileOpen, setMobileOpen] = useState(false)
  const [colorChange, setColorchange] = useState(false)
  const theme = useTheme()

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState)
  }

  const changeNavbarColor = () => {
    if (window.scrollY > 10) {
      setColorchange(true)
    } else {
      setColorchange(false)
    }
  }
  window.addEventListener('scroll', changeNavbarColor)

  const handleTop = () => {
    console.log('scrolled to top')

    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  const navigate = useNavigate()
  const drawer = (
    <Box
      onClick={handleDrawerToggle}
      sx={{ textAlign: 'center', background: '#FFE500', position: 'relative' }}
    >
      <div className="hamBurgerv3">
        <MenuIcon />
      </div>
      <Box sx={{ my: 2 }}>
        <Typography
          variant="logo"
          color="black"
          zIndex="10"
          onClick={() => {
            navigate('/')
            handleTop()
          }}
          sx={{ cursor: 'pointer' }}
        >
          <FinalLogoSvg />
        </Typography>
        {/* <Box
          height="62px"
          width="180px"
          onClick={() => {
            navigate('/')
          }}
          sx={{ cursor: 'pointer' }}
        >
          <img
            src={LogoFinal}
            alt=""
            style={{ maxHeight: 'inherit', maxWidth: 'inherit' }}
          />
          
        </Box> */}
      </Box>
      <Divider />
      <List>
        {navItems.map((item) => (
          <ListItem key={item.title} disablePadding>
            <ListItemButton
              sx={{ textAlign: 'center' }}
              component={NavLink}
              to={item.link}
              onClick={() => {
                handleTop()
              }}
            >
              <ListItemText primary={item.title} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  )

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        component="nav"
        sx={{ bgcolor: colorChange && 'black', zIndex: 1800 }}
      >
        <Container
          sx={{
            display: 'flex',
            justifyContent: { md: 'center' },
          }}
          maxWidth="2xl"
        >
          <Toolbar sx={{ width: '100%' }}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent={{ xs: 'space-between' }}
              width="100%"
            >
              <div
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                className="hamBurgerv2"
              >
                {/* <IconButton
                  color="inherit"
               
                  sx={{
                    // mr: 2,
                    display: { md: 'none' },
                  }}
                >
                </IconButton> */}
                <MenuIcon />
              </div>

              <Box
                height="62px"
                width="180px"
                onClick={() => {
                  navigate('/')
                  handleTop()
                }}
                sx={{ cursor: 'pointer' }}
              >
                <img
                  src={LogoFinal}
                  alt=""
                  style={{ maxHeight: 'inherit', maxWidth: 'inherit' }}
                />
              </Box>
              <Box
                sx={{
                  display: {
                    xs: 'none',
                    md: 'block',
                  },
                }}
              >
                {navItems.map((item) => (
                  <Button
                    key={item}
                    sx={{
                      color: 'rgba(255, 255, 255, 0.4) !important',
                      '@media (max-width: 1200px)': {
                        fontSize: '6px !important',
                      },
                    }}
                    LinkComponent={NavLink}
                    to={item.link}
                    // target={item.title === 'Careers' && '_blank'}
                    onClick={() => {
                      handleTop()
                    }}
                  >
                    {item.title}
                  </Button>
                ))}
              </Box>
              <Box
                sx={{
                  minWidth: '176px', // Media query for mobile devices
                  '@media (max-width: 767px)': {
                    minWidth: '0',
                  },
                }}
              >
                <Button
                  className="discordIcon"
                  variant="contained"
                  sx={{
                    display: 'flex',
                    padding: '10px 24px',
                    borderRadius: '25px',
                    backgroundColor: 'transparent',
                    color: theme.palette.primary.main,
                    // marginLeft: '10px !important',
                    boxShadow: 'none',
                    border: '1px solid #FFE500',
                    ':hover': { bgcolor: 'transparent' },
                    gap: '11px',
                    mr: '0',
                    width: '100%',
                    maxWidth: '75px',
                  }}
                  onClick={() =>
                    window.open(
                      'https://x.com/polarizenetwork?t=mWBx5BeSMfH2-B0yShnKrQ&s=09',
                      '_blank',
                      'noopener,noreferrer',
                    )
                  }
                >
                  <Typography
                    className="discord"
                    display="none"
                    fontSize={12}
                    fontWeight={400}
                    fontFamily="Krona One"
                    noWrap
                  >
                    Join Twitter
                  </Typography>
                  <TwitterIcon />
                  {/* SIGN IN */}
                </Button>
              </Box>
            </Stack>
          </Toolbar>
        </Container>
      </AppBar>
      <nav>
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            zIndex: '1900',
            display: { xs: 'block', md: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
      <Box component="main" sx={{ p: 3 }}>
        <Toolbar />
      </Box>
    </Box>
  )
}

export default NavBar
