import * as React from 'react'
const SuccessIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={150}
    height={150}
    fill="none"
    {...props}
  >
    <path
      fill="#D5DEED"
      d="M75.001 132.974a57.984 57.984 0 0 1-57.858-61.61 57.981 57.981 0 0 1 10.245-29.447 3.516 3.516 0 0 1 5.77 4.02 50.928 50.928 0 1 0 14.248-13.763 3.516 3.516 0 1 1-3.81-5.91A57.977 57.977 0 1 1 75 132.973Z"
      opacity={0.5}
    />
    <path
      fill="#FFE500"
      d="M38.012 38.547a3.516 3.516 0 0 1-2.39-6.093l.649-.59a3.514 3.514 0 1 1 4.699 5.228l-.57.52a3.516 3.516 0 0 1-2.388.935ZM93.502 61.792a2.758 2.758 0 0 0-3.915 0l-20.542 20.57-8.63-8.658a2.819 2.819 0 1 0-3.916 4.053l10.588 10.588a2.758 2.758 0 0 0 3.915 0l22.5-22.5a2.758 2.758 0 0 0 0-4.053Z"
    />
  </svg>
)
export default SuccessIcon
