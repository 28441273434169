import {
  AppBar,
  Box,
  Container,
  Stack,
  Toolbar,
} from '@mui/material'
import PersonIcon from '../../../resources/images/Retail/default_profile.jpg'
import LogoFinal from '../../../resources/images/Polarize_Logo_Final.svg'
import bellIcon from '../../../resources/images/Retail/bell.svg'
import { useState } from 'react';
import NotificationModal from '../Notification/Notification';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MyProfileModal from '../Profile/MyProfileModal';

function DashboardNavBar({ userData }) {
  const [showNotifications, setShowNotifications] = useState(false);
  const [profileModal, setProfileModal] = useState(false);
  const username = localStorage.getItem("username");

  const notifications = [
    'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s.',
    'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s.',
    'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s.',
    'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s.',
  ];

  const handleBellClick = () => {
    setShowNotifications(!showNotifications);
  };

  const handleProfileClick = () => {
    setProfileModal(!profileModal);
  };

  const handleClose = () => {
    setShowNotifications(false);
  };
  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar
        component="nav"
        sx={{ bgcolor: '#080707 !important', zIndex: 1800, boxShadow: "0px 4px 10px 0px #FFFFFF0D !important" }}
      >
        <Container
          sx={{
            display: 'flex',
            justifyContent: { md: 'center' },
          }}
          maxWidth="2xl"
        >
          <Toolbar sx={{ width: '100%' }}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent={{ xs: 'space-between' }}
              width="100%"
            >
              <Box
                height="62px"
                width="180px"
                sx={{ cursor: 'pointer' }}
              >
                <img
                  src={LogoFinal}
                  alt=""
                  style={{ maxHeight: 'inherit', maxWidth: 'inherit' }}
                />
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'end', gap: "10px" }}>
                <img src={bellIcon} alt="notification" onClick={handleBellClick} />
                <img src={PersonIcon} alt="User Avatar" height={35} width={35} style={{ borderRadius: "50%" }} />
                <span>{userData?.full_name || username}</span>
                <KeyboardArrowDownIcon onClick={handleProfileClick} />
              </Box>
            </Stack>
          </Toolbar>
        </Container>
      </AppBar>
      {showNotifications && (
        <NotificationModal notifications={[]} onClose={handleClose} />
      )}
      {profileModal && (
        <MyProfileModal />
      )}
    </Box>
  )
}

export default DashboardNavBar
