// Tooltip.js
import React from 'react';
import './profile.css';

const Tooltip = ({ content }) => {
    return (
        <div className="tooltip">
            {content.map((item, index) => (
                <div key={index} className="tooltip-item">
                    <span className="tooltip-label">{item.label}:</span>
                    <span className="tooltip-value">{item.value}</span>
                </div>
            ))}
        </div>
    );
};

export default Tooltip;
